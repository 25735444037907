const calculateCentroid = (polygonCoordinates: number[]) => {
    const pairs = [];

    // Convert the flat array of coordinates into pairs
    for (let i = 0; i < polygonCoordinates.length; i += 2) {
        pairs.push([polygonCoordinates[i], polygonCoordinates[i + 1]]);
    }

    // Calculate the centroid using the shoelace formula
    let area = 0;
    let cx = 0;
    let cy = 0;

    for (let i = 0; i < pairs.length; i++) {
        const xi = pairs[i][0];
        const yi = pairs[i][1];
        const xi1 = pairs[(i + 1) % pairs.length][0];
        const yi1 = pairs[(i + 1) % pairs.length][1];

        const commonFactor = xi * yi1 - xi1 * yi;
        area += commonFactor;
        cx += (xi + xi1) * commonFactor;
        cy += (yi + yi1) * commonFactor;
    }

    area /= 2;
    cx /= 6 * area;
    cy /= 6 * area;

    return { x: cx, y: cy };
};

export const calculateResponsiveCoordinates = (
    coordinates: HTMLAreaElement['coords'],
    containerWidth: number,
    containerHeight: number,
    imageWidth: number,
    imageHeight: number,
) => {
    const percentageWidth = containerWidth / imageWidth;
    const percentageHeight = containerHeight / imageHeight;

    const points = coordinates
        .split(',')
        .map((coord, index) =>
            index % 2 === 0 ? Number(coord) * percentageWidth : Number(coord) * percentageHeight,
        );

    const centroid = calculateCentroid(points);
    const radiusScalingFactor = 0.008;
    const r = Math.min(containerWidth, containerHeight) * radiusScalingFactor;

    return { points: points.join(','), centroid: { ...centroid, r } };
};
