import { FC, useRef } from 'react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    IconButton,
    Collapse,
    useColorModeValue,
    useDisclosure,
    Container,
    ColorProps,
    BoxProps,
} from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useIntersectionObserver } from 'usehooks-ts';

import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import Logo from '../Logo/Logo';
import { HeaderProps } from './Header.types';
import DesktopNav from './Navigation/DesktopNav';
import MobileNav from './Navigation/MobileNav';

const HEADER_EXPANDED_BG: ColorProps['color'] = 'transparent';
const HEADER_COLLAPSED_BG: ColorProps['color'] = 'gray.100';
export const HEADER_HEIGHT: BoxProps['height'] = ['80px', '100px'];
export const HEADER_COLLAPSED_HEIGHT: BoxProps['height'] = ['60px', '80px'];

const Header: FC<HeaderProps> = ({ navItems, logoOnly = false, fixed = false, logoProps, ...boxProps }) => {
    const { isOpen, onToggle } = useDisclosure();
    const intersectionRef = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(intersectionRef, {});
    const isVisible = !!entry?.isIntersecting;
    const headerStyleProps: HeaderProps = {
        shadow: ['lg', 'lg', !fixed ? (!isVisible ? 'lg' : 'none') : 'none'],
    };
    const backgroundColor: ColorProps['color'] = !fixed
        ? !isVisible
            ? HEADER_COLLAPSED_BG
            : [HEADER_COLLAPSED_BG, HEADER_COLLAPSED_BG, HEADER_EXPANDED_BG]
        : [HEADER_COLLAPSED_BG, HEADER_COLLAPSED_BG, HEADER_EXPANDED_BG];

    return (
        <>
            <Box pos="absolute" top={0} left={0} w="0" h="0" ref={intersectionRef} />
            <Box as="header" position="fixed" width="100%" zIndex={3} {...boxProps} {...headerStyleProps}>
                <Flex
                    bg={backgroundColor}
                    color={useColorModeValue('gray.600', 'white')}
                    h={isVisible ? HEADER_HEIGHT : HEADER_COLLAPSED_HEIGHT}
                    py={[1, 0]}
                    px={[4]}
                    transition="0.5s"
                    align="center">
                    <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={['flex', 'flex', 'none']}>
                        <IconButton
                            onClick={onToggle}
                            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                            variant="ghost"
                            aria-label="Toggle Navigation"
                        />
                    </Flex>
                    <Container maxW="container.lg">
                        <Flex justifyContent={logoOnly ? 'center' : 'space-between'}>
                            <AnchorLink to="/">
                                <Logo {...logoProps} />
                            </AnchorLink>
                            {!logoOnly && (
                                <Flex display={['none', 'none', 'flex']} alignItems="center" ml={10}>
                                    <DesktopNav navItems={navItems} />
                                </Flex>
                            )}
                        </Flex>
                    </Container>
                </Flex>

                <Collapse in={isOpen} animateOpacity>
                    <MobileNav navItems={navItems} bg={HEADER_COLLAPSED_BG} />
                </Collapse>
                <Flex width="full" justifyContent="center">
                    <Box
                        width={isVisible ? 'container.lg' : 'full'}
                        height="0.7px"
                        backgroundColor={isVisible ? 'gray.100' : 'gray.100'}
                        transition="0.7s"
                    />
                </Flex>
            </Box>
            <LanguageSwitch />
        </>
    );
};

export default Header;
