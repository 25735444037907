import { extendTheme } from '@chakra-ui/react';
const theme = {
    colors: {
        primary: '#d6f7a3',
        secondary: '#C4FFB2',
        tertiary: '#b7e3cc',
        accent: '#7B4B94',
        accentSecondary: '#7D82B8',
    },
    sizes: {
        90: '90%',
    },
};

export default extendTheme(theme);
