import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { Property } from '@/hooks/land/useProperties';

export const propertiesAtom = atom<Property[]>([]);

export const queriedPropertiesAtom = atomWithStorage<number[]>('queriedProperties', []);

export const selectedPropetyAtom = atom<Property | null>(null);
export const hoveredPropetyAtom = atom<Property | null>(null);
