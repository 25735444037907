import { FC } from 'react';

import { Box, Accordion, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ContextKeys } from '@/enums';

import LandExpense from './LandExpense/LandExpense';
import { LandExpensesProps } from './LandExpenses.types';

const LandExpenses: FC<LandExpensesProps> = ({ contextKey = ContextKeys.TWO, ...boxProps }) => {
    const { t } = useTranslation();

    return (
        <Box {...boxProps}>
            <Text fontSize="l" fontWeight="100" mb={5}>
                {t(`${contextKey}.expenses.subTitle`)}
            </Text>
            <Accordion defaultIndex={[0]} allowMultiple>
                <LandExpense expenseKey={`${contextKey}.expenses.expenses.electricity`} />
                <LandExpense expenseKey={`${contextKey}.expenses.expenses.road`} />
                <LandExpense expenseKey={`${contextKey}.expenses.expenses.tax`} />
                <LandExpense expenseKey={`${contextKey}.expenses.expenses.water`} />
            </Accordion>
        </Box>
    );
};

export default LandExpenses;
