import { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { LandAdditionalStepProps } from './LandAdditionalStep.types';

const LandAdditionalStep: FC<LandAdditionalStepProps> = ({ children, ...boxProps }) => (
    <Box
        shadow="md"
        p="10"
        fontSize="md"
        width="full"
        borderRadius="md"
        bgGradient="linear(to-tr, white, gray.50)"
        _hover={{
            shadow: 'xl',
        }}
        transition="1s"
        {...boxProps}>
        {children}
    </Box>
);

export default LandAdditionalStep;
