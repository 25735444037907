import { FC } from 'react';

import {
    Box,
    Button,
    Divider,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Flex,
    Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { navigate } from 'gatsby';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { FILL_MAP } from '@/constants/four/scheme';
import { FourPropertyStatus } from '@/enums';
import { selectedFourPropetyAtom } from '@/state/four/fourProperties';
import { formatPrice } from '@/utils/formatPrice';
import { isPropertyAvailable } from '@/utils/four/isPropertyAvailable';

import FourPropertiesScheme from '../FourPropertiesScheme/FourPropertiesScheme';
import { FourPropertyDrawerProps } from './FourPropertyDrawer.types';

const FourPropertyDrawer: FC<FourPropertyDrawerProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const [selectedProperty] = useAtom(selectedFourPropetyAtom);

    const handleNavigate = (to: string) => () => {
        if (onClose) {
            onClose();
        }
        navigate(to);
    };

    if (!selectedProperty) {
        return null;
    }

    return (
        <DrawerContent
            bg="whiteAlpha.800"
            backdropFilter="blur(6px)"
            ml={[0, 4]}
            my={[0, 4]}
            borderRadius={['none', 'lg']}>
            <DrawerCloseButton />
            <DrawerHeader>{t('common.propertyNumber', { number: selectedProperty.number })}</DrawerHeader>
            <DrawerBody fontSize="lg">
                <Flex justifyContent="space-between">
                    <Text>{t('land.scheme.headers.status')}</Text>
                    <Text
                        fontWeight="semibold"
                        color={FILL_MAP[selectedProperty.statusText as FourPropertyStatus]}>
                        {selectedProperty.statusText}
                    </Text>
                </Flex>
                <Divider my="2" borderColor="blackAlpha.300" />
                <Flex justifyContent="space-between">
                    <Text>{t('filters.size')}</Text>
                    <Text fontWeight="semibold">
                        {selectedProperty.size} {t('common.sqm')}
                    </Text>
                </Flex>
                <Divider my="2" borderColor="blackAlpha.300" />
                <Flex justifyContent="space-between">
                    <Text>{t('four.scheme.streetArea')}</Text>
                    <Text fontWeight="semibold" textAlign="right">
                        {selectedProperty.streetArea} {t('common.m')}
                    </Text>
                </Flex>
                <Divider my="2" borderColor="blackAlpha.300" />
                <Flex justifyContent="space-between">
                    <Text>
                        {t('filters.price')} {t('common.withVat')}
                    </Text>
                    <Text fontWeight="semibold">{selectedProperty && formatPrice(selectedProperty.price)}</Text>
                </Flex>
                <Flex justifyContent="space-between" mt="2" fontSize="sm" fontWeight="light">
                    <Text>{t('common.lastChange')}</Text>
                    <Text>{format(selectedProperty.modifiedTime, 'dd/MM/yyyy')}</Text>
                </Flex>
                <Box mt="6">
                    <Button variant="unstyled" width="full" height="full" onClick={handleNavigate('/2024/scheme')}>
                        <FourPropertiesScheme highlightedProperty={selectedProperty} />
                    </Button>
                </Box>
                <Box mt="6">
                    {isPropertyAvailable(selectedProperty) && (
                        <Box>
                            <Button
                                colorScheme="green"
                                w="full"
                                onClick={handleNavigate(`/query/2024/${selectedProperty.number * 1000}`)}>
                                {t('common.query')}
                            </Button>
                            <Flex flexDirection="column" mt="2">
                                <Text textAlign="center" fontWeight="light">
                                    {t('common.orCall')}
                                </Text>
                                <Text textAlign="center">{t('common.contact.phone')}</Text>
                            </Flex>
                        </Box>
                    )}
                </Box>
                <Box mt="4">
                    <Button
                        onClick={handleNavigate('/2024/scheme')}
                        colorScheme="blackAlpha"
                        w="full"
                        variant="outline">
                        {t('four.availableProperties')}
                    </Button>
                </Box>
            </DrawerBody>
        </DrawerContent>
    );
};

export default FourPropertyDrawer;
