import { FC, useMemo } from 'react';

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    Box,
    Icon,
    chakra,
    useColorModeValue,
    HStack,
    Text,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle, MdError, MdWarning } from 'react-icons/md';

import { EStatusIcon } from '@/enums';
import useProperties, { Property } from '@/hooks/land/useProperties';
import { hoveredPropetyAtom, selectedPropetyAtom } from '@/state/land/properties';
import { formatPrice } from '@/utils/formatPrice';

import { LandPropertiesTableProps } from './LandPropertiesTable.types';

interface IStatusIcon {
    statusIcon: EStatusIcon;
}

const StatusIcon: FC<IStatusIcon> = ({ statusIcon }) => {
    if (statusIcon === EStatusIcon.GREEN) {
        return <Icon as={MdCheckCircle} color="green.300" mr={3} />;
    } else if (statusIcon === EStatusIcon.ORANGE) {
        return <Icon as={MdWarning} color="orange.300" mr={3} />;
    }
    return <Icon as={MdError} color="red.300" mr={3} />;
};

const LandPropertiesTable: FC<LandPropertiesTableProps> = ({
    showDisclaimer = true,
    status = true,
    onModalOpen,
    ...boxProps
}) => {
    const { t } = useTranslation();
    const [properties] = useProperties();
    const [, setSelectedProperty] = useAtom(selectedPropetyAtom);
    const [hoveredProperty, setHoveredProperty] = useAtom(hoveredPropetyAtom);

    const filteredProperties = useMemo(
        () =>
            properties.filter(property =>
                status ? property.statusIcon === EStatusIcon.GREEN : property.statusIcon !== EStatusIcon.GREEN,
            ),
        [properties, status],
    );

    const onMouseEnter = (property: Property) => () => {
        setHoveredProperty(property);
    };

    const onMouseLeave = () => {
        setHoveredProperty(null);
    };

    const handlePropertyClick = (property: Property) => () => {
        if (onModalOpen) {
            setSelectedProperty(property);
            onModalOpen();
        }
    };

    return (
        <Box w="full" overflowX="auto" {...boxProps}>
            <Table size="sm" colorScheme="gray">
                {showDisclaimer && (
                    <TableCaption>
                        <chakra.sup>*</chakra.sup> {t('land.scheme.disclaimer')}
                    </TableCaption>
                )}
                <Thead>
                    <Tr>
                        <Th colSpan={2} />
                        <Th isNumeric>{t('land.scheme.singlePayment')}</Th>
                        <Th isNumeric colSpan={2} textAlign="left">
                            {t('land.scheme.multiplePayments')}
                        </Th>
                        <Th colSpan={2} />
                    </Tr>
                </Thead>
                <Thead>
                    <Tr>
                        <Th>{t('common.number')}</Th>
                        <Th>{t('filters.size')}</Th>
                        <Th isNumeric>
                            {t('filters.price')} {t('common.withVat')}
                        </Th>
                        <Th isNumeric backgroundColor={useColorModeValue('gray.200', 'gray.900')}>
                            {t('land.scheme.headers.firstPayment')}
                        </Th>
                        <Th isNumeric backgroundColor={useColorModeValue('gray.200', 'gray.900')}>
                            {t('land.scheme.headers.secondPayment')}
                        </Th>
                        <Th textAlign="center" colSpan={1}>
                            {t('land.scheme.headers.status')}
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredProperties.map(property => (
                        <Tr
                            key={property.number}
                            onMouseEnter={onMouseEnter(property)}
                            onMouseLeave={onMouseLeave}
                            onClick={handlePropertyClick(property)}
                            cursor="pointer"
                            transition="0.15s"
                            bg={
                                hoveredProperty && hoveredProperty.number === property.number
                                    ? 'gray.100'
                                    : 'white'
                            }>
                            <Td>
                                <HStack spacing={1}>
                                    <StatusIcon statusIcon={property.statusIcon} />
                                    <Text fontWeight={100}>{property.number}</Text>
                                </HStack>
                            </Td>
                            <Td>
                                <Text>
                                    {property.size} {t('common.sqm')}
                                </Text>
                            </Td>
                            <Td isNumeric>{property.price === 0 ? '' : formatPrice(property.price)}</Td>
                            <Td isNumeric>{property.priceFirst === 0 ? '' : formatPrice(property.priceFirst)}</Td>
                            <Td isNumeric>
                                {property.priceSecond === 0 ? '' : formatPrice(property.priceSecond)}
                            </Td>
                            <Td textAlign="center">{property.statusText}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default LandPropertiesTable;
