import { atomWithStorage } from 'jotai/utils';

import { BooleanFilter, RangeFilter } from './filters.types';

const initialRangeFilter = {
    min: 0,
    max: 100,
};

export const priceFilterAtom = atomWithStorage<RangeFilter>('priceFilter', initialRangeFilter);
export const sizeFilterAtom = atomWithStorage<RangeFilter>('sizeFilter', initialRangeFilter);
export const availabilityFilterAtom = atomWithStorage<BooleanFilter>('availabilityFilter', false);
