import { FC } from 'react';

import { Box, ListItem, ListIcon, List, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AiFillStar } from 'react-icons/ai';

import { Trans } from '@/components/common';

import { LandBuildingServiceProps } from './LandBuildingService.types';

const LandBuildingService: FC<LandBuildingServiceProps> = ({ ...boxProps }) => {
    const { t } = useTranslation();
    return (
        <Box {...boxProps}>
            <List spacing={3} mb={5}>
                <Text fontSize="m" fontWeight="semibold" mb={5}>
                    {t('land.details.buildingService.callToAction.title')}
                </Text>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    {t('land.details.buildingService.callToAction.left')}
                </ListItem>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    {t('land.details.buildingService.callToAction.right')}
                </ListItem>
            </List>
            <Text fontSize="m" fontWeight="semibold" mb={5}>
                {t('land.details.buildingService.engage')}
            </Text>
            <List spacing={3}>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    <Trans i18nKey="land.details.buildingService.steps.1" />
                </ListItem>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    <Trans i18nKey="land.details.buildingService.steps.2" />
                </ListItem>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    <Trans i18nKey="land.details.buildingService.steps.3" />
                </ListItem>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    <Trans i18nKey="land.details.buildingService.steps.4" />
                </ListItem>
                <ListItem>
                    <ListIcon as={AiFillStar} color="orange.300" />
                    <Trans i18nKey="land.details.buildingService.steps.5" />
                </ListItem>
            </List>
        </Box>
    );
};

export default LandBuildingService;
