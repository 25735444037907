export enum EStatusIcon {
    GREEN = 'green',
    ORANGE = 'orange',
    RED = 'red',
}

export enum ProjectTypes {
    LAND = 'land',
    HOUSE = 'house',
    ALL = 'all',
}

export enum GalleryPeriods {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year',
}

export enum FourPropertyStatus {
    AVAILABLE = 'Свободен',
    RESERVED = 'Капариран',
    RESERVED2 = 'Резервиран',
    SOLD = 'Продаден',
}

export enum ContextKeys {
    TWO = 'land',
    FOUR = 'four',
}

export enum DrawerTypes {
    FOUR_PROPERTY = 'FOUR_PROPERTY',
    TWO_PROPERTY = 'TWO_PROPERTY',
}
