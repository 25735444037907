import { FC, useMemo } from 'react';

import { Flex, Highlight, Icon, Text } from '@chakra-ui/react';
import { FaLeaf } from 'react-icons/fa';

import { LogoProps } from './Logo.types';

const COLLAPSED_COLORS = {
    icon: 'green.600',
    primary: 'green.600',
    secondary: 'green.900',
};

const EXPANDED_COLORS = {
    icon: [COLLAPSED_COLORS.icon, COLLAPSED_COLORS.icon, 'whiteAlpha.800'],
    primary: [COLLAPSED_COLORS.primary, COLLAPSED_COLORS.primary, 'whiteAlpha.600'],
    secondary: [COLLAPSED_COLORS.secondary, COLLAPSED_COLORS.secondary, 'white'],
};

const COLORS_MAPPING = {
    0: {
        0: EXPANDED_COLORS,
        1: COLLAPSED_COLORS,
    },
    1: {
        0: COLLAPSED_COLORS,
        1: COLLAPSED_COLORS,
    },
};

const Logo: FC<LogoProps> = ({ isCollapsed = false, isInverseColors = false, ...boxProps }) => {
    const colors = useMemo(
        () =>
            COLORS_MAPPING[+isCollapsed as keyof typeof COLORS_MAPPING][
                +isInverseColors as keyof typeof COLORS_MAPPING
            ],
        [isCollapsed, isInverseColors],
    );
    return (
        <Flex {...boxProps}>
            <Icon as={FaLeaf} color={colors.icon} w={[25, 50]} h={[25, 50]} />
            <Text
                ml="2"
                pos="relative"
                fontWeight="hairline"
                fontSize={['xl', '3xl']}
                textTransform="uppercase"
                fontFamily={'heading'}
                color={colors.primary}
                transition="0.5s"
                whiteSpace="nowrap">
                <Highlight query="Nature" styles={{ color: colors.secondary }}>
                    BackToNature
                </Highlight>
            </Text>
        </Flex>
    );
};

export default Logo;
