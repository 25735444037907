import { FC, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ANIMATIONS } from '@/constants/animations';

import { AnimatedBoxProps } from './AnimatedBox.types';

const AnimatedBox: FC<AnimatedBoxProps> = ({
    children,
    animationType,
    triggerOnce = false,
    threshold = 0,
    ...boxProps
}) => {
    const control = useAnimation();
    const [ref, inView] = useInView({ triggerOnce, threshold });
    const variants = ANIMATIONS[animationType];

    useEffect(() => {
        if (inView) {
            control.start('after');
        } else {
            control.start('before');
        }
    }, [control, inView]);

    return (
        <Box {...boxProps}>
            <motion.div className="box" ref={ref} variants={variants} initial="hidden" animate={control}>
                {children}
            </motion.div>
        </Box>
    );
};

export default AnimatedBox;
