import { graphql, useStaticQuery } from 'gatsby';

const useLastChanges = () => {
    const data = useStaticQuery(graphql`
        query {
            currentBuildDate {
                currentDate
            }
        }
    `);
    return data.currentBuildDate.currentDate as string;
};

export default useLastChanges;
