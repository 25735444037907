import { FC, useCallback } from 'react';

import { Box, Link, LinkProps, Stack, Text, TextProps } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { isLinkActive } from '@/utils/isLinkActive';

import { NavItem, NavProps } from '../Header.types';

const MobileNav: FC<NavProps> = ({ navItems }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const commonProps: Partial<LinkProps> & Partial<TextProps> = {
        textAlign: 'center',
        color: 'white',
        _hover: {
            textDecoration: 'none',
            opacity: 1,
        },
        transition: '0.5s',
    };

    const getLinkProps = useCallback(
        (navItem: NavItem) => {
            const isActive = isLinkActive(navItem, location.pathname);
            if (isActive) {
                return {
                    opacity: 1,
                    ...commonProps,
                };
            }
            return {
                opacity: 0.5,
                ...commonProps,
            };
        },
        [location.pathname, commonProps],
    );

    return (
        <Stack spacing={[2, 4]}>
            {navItems &&
                navItems.map(navItem => (
                    <Box key={navItem.label}>
                        {navItem.href || location.pathname !== '/' ? (
                            <Link
                                as={AnchorLink}
                                to={navItem.href ?? navItem.page}
                                target={navItem.newTab ? '_blank' : '_self'}
                                fontSize={['md', '2xl']}
                                display="flex"
                                justifyContent="center"
                                fontWeight="extrabold"
                                textTransform="uppercase"
                                {...getLinkProps(navItem)}>
                                {t(navItem.label)}
                            </Link>
                        ) : (
                            <Text
                                fontWeight="extrabold"
                                textTransform="uppercase"
                                fontSize={['2xl', '5xl']}
                                mt="4"
                                mb="2"
                                {...getLinkProps(navItem)}>
                                {t(navItem.label)}
                            </Text>
                        )}
                        {navItem.children && (location.pathname === navItem.page || location.pathname === '/') && (
                            <Stack>
                                {navItem.children.map(child => (
                                    <Link
                                        key={child.label}
                                        as={AnchorLink}
                                        py="1"
                                        href={child.href}
                                        target={child.newTab ? '_blank' : '_self'}
                                        to={child.href as string}
                                        fontSize={['sm', 'xl']}
                                        fontWeight="semibold"
                                        opacity={0.6}
                                        {...commonProps}>
                                        {t(child.label)}
                                    </Link>
                                ))}
                            </Stack>
                        )}
                    </Box>
                ))}
        </Stack>
    );
};

export default MobileNav;
