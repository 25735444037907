import { FC } from 'react';

import { Text } from '@chakra-ui/react';

import { LandTitleProps } from './LandTitle.types';

const LandTitle: FC<LandTitleProps> = ({ ...textProps }) => (
    <Text fontSize="2xl" fontWeight="100" mb={5} {...textProps} />
);

export default LandTitle;
