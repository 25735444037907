import { FC } from 'react';

import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Trans } from '@/components/common';

import { LandExpenseProps } from './LandExpense.types';

const LandExpense: FC<LandExpenseProps> = ({ expenseKey, ...accordionItemProps }) => {
    const { t } = useTranslation();
    const titleKey = `${expenseKey}.title`;
    const textKey = `${expenseKey}.text`;

    return (
        <AccordionItem {...accordionItemProps}>
            <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={'lg'} fontWeight={600}>
                    {t(titleKey)}
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
                <Trans i18nKey={textKey} />
            </AccordionPanel>
        </AccordionItem>
    );
};

export default LandExpense;
