import { FC } from 'react';

import { Link, Text, VStack } from '@chakra-ui/react';
import { TFuncKey } from 'i18next';
import { Trans as I18Trans, TransProps } from 'react-i18next';
const Trans: FC<TransProps<TFuncKey>> = ({ ...transProps }) => (
    <I18Trans
        components={{
            bold: <Text fontWeight="bold" display="inline" />,
            highlight: <Text color="green.500" display="inline" />,
            'watertax-formula': (
                <VStack mt={3}>
                    <Text borderBottom={'1px'} borderBottomColor={'gray.500'} color="gray.500" fontSize={'sm'}>
                        <Trans i18nKey="land.expenses.waterTax" />
                    </Text>
                    <Text color="gray.500">52</Text>
                </VStack>
            ),
            'declare-link': <Link href="https://bit.ly/3yWVN4w" target="_blank" />,
            'cadastre-link': <Link href="https://kais.cadastre.bg" target="_blank" />,
            'cadastre-contact-link': <Link href="https://www.cadastre.bg/contacts/sgkk-sofiya" target="_blank" />,
            'elin-pelin-link': <Link href="https://www.elinpelin.org/section-374-content.html" target="_blank" />,
            'chez-link': <Link href="https://bit.ly/3k2DwPn" target="_blank" />,
            'elin-pelin-2-link': <Link href="https://www.elinpelin.org/currentNews-1954-newitem.html" />,
            'er-arch-link': <Link href="https://www.er-arch.com" target="_blank" />,
        }}
        {...transProps}
    />
);

export default Trans;
