import { FC, useLayoutEffect, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';

import { calculateResponsiveCoordinates } from '@/utils/calculateResponsiveCoordinates';

import { SchemeItemType, SchemeMapProps } from './SchemeMap.types';

const SchemeMap: FC<SchemeMapProps> = ({
    imageWidth,
    imageHeight,
    mappedItems,
    keyExtractor,
    coordsExtractor,
    fillCondition,
    opacityCondition,
    onClick,
    onMouseEnter,
    onMouseLeave,
    containerProps,
    shape = 'polygon',
}) => {
    const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
    const targetRef = useRef<HTMLDivElement>(null);

    const handleClick = (item: SchemeItemType) => () => {
        if (onClick) {
            onClick(item);
        }
    };

    const handleOnMouseEnter = (item: SchemeItemType) => () => {
        if (onMouseEnter) {
            onMouseEnter(item);
        }
    };

    const handleOnMouseLeave = (item: SchemeItemType) => () => {
        if (onMouseLeave) {
            onMouseLeave(item);
        }
    };

    useLayoutEffect(() => {
        const updateDimensions = () => {
            const containerElement = targetRef.current;
            if (containerElement) {
                const containerWidth = containerElement.clientWidth || 100;
                const containerHeight = containerElement.clientHeight || 100;
                setSvgDimensions({ width: containerWidth, height: containerHeight });
            }
        };

        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <Box as="svg" position="absolute" top={0} width="full" height="full" ref={targetRef} {...containerProps}>
            {mappedItems.map(item => {
                const { points } = calculateResponsiveCoordinates(
                    coordsExtractor(item),
                    svgDimensions.width,
                    svgDimensions.height,
                    imageWidth,
                    imageHeight,
                );
                return (
                    <Box
                        as="g"
                        key={keyExtractor(item)}
                        onClick={handleClick(item)}
                        onMouseEnter={handleOnMouseEnter(item)}
                        onMouseLeave={handleOnMouseLeave(item)}
                        cursor="pointer">
                        <Box
                            as={shape}
                            points={shape === 'polygon' ? points : undefined}
                            cx={shape === 'circle' ? points.split(',')[0] : undefined}
                            cy={shape === 'circle' ? points.split(',')[1] : undefined}
                            r={shape === 'circle' ? points.split(',')[2] : undefined}
                            fill={fillCondition ? fillCondition(item) : 'transparent'}
                            opacity={opacityCondition ? opacityCondition(item) : 0}
                            transition="opacity 0.3s"
                            stroke="black"
                            strokeWidth="1"
                        />
                        {/* <Box
                            as="circle"
                            cx={centroid.x}
                            cy={centroid.y}
                            r={centroid.r}
                            fill={fillCondition ? fillCondition(item) : 'transparent'}
                            stroke="black"
                            strokeWidth="0.3"
                        /> */}
                    </Box>
                );
            })}
        </Box>
    );
};

export default SchemeMap;
