export const FEATURES_FOUR = [
    'road',
    'electricity',
    'gas',
    'water',
    'drain',
    'sewage',
    'internet',
    'parking',
    'fences',
];
