import { FC, useMemo, useRef } from 'react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, useColorModeValue, useDisclosure, ColorProps, BoxProps } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useIntersectionObserver } from 'usehooks-ts';

import { LanguageSwitch } from '@/components/v2';

import Logo from '../Logo/Logo';
import { HeaderProps } from './Header.types';
import DesktopNav from './Navigation/DesktopNav';
import MobileNav from './Navigation/MobileNav';

const HEADER_EXPANDED_BG: ColorProps['color'] = 'transparent';
const HEADER_COLLAPSED_BG: ColorProps['color'] = 'gray.100';
const HEADER_EXPANDED_BG_INVERSE: ColorProps['color'] = 'transparent';
const HEADER_COLLAPSED_BG_INVERSE: ColorProps['color'] = 'gray.100';

const HEADER_BG_MAPPING = {
    0: {
        0: HEADER_EXPANDED_BG,
        1: HEADER_EXPANDED_BG_INVERSE,
    },
    1: {
        0: HEADER_COLLAPSED_BG,
        1: HEADER_COLLAPSED_BG_INVERSE,
    },
};

export const HEADER_HEIGHT: BoxProps['height'] = ['60px', '80px', '120px'];
export const HEADER_COLLAPSED_HEIGHT: BoxProps['height'] = ['50px', '70px', '80px'];

const Header: FC<HeaderProps> = ({ navItems, fixed = false, isInverseColors = false, logoProps }) => {
    const { isOpen, onToggle } = useDisclosure();
    const intersectionRef = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(intersectionRef, {});
    const isCollapsed = useMemo(() => {
        if (entry) {
            return !entry.isIntersecting;
        }
        return false;
    }, [entry]);
    const headerStyleProps: HeaderProps = {
        shadow: ['lg', 'lg', !fixed ? (isCollapsed ? 'lg' : 'none') : 'none'],
    };
    const backgroundColor: ColorProps['color'] = useMemo(
        () =>
            isCollapsed
                ? HEADER_BG_MAPPING[1][+isInverseColors as keyof typeof HEADER_BG_MAPPING]
                : [
                      HEADER_BG_MAPPING[1][+isInverseColors as keyof typeof HEADER_BG_MAPPING],
                      HEADER_BG_MAPPING[1][+isInverseColors as keyof typeof HEADER_BG_MAPPING],
                      HEADER_BG_MAPPING[0][+isInverseColors as keyof typeof HEADER_BG_MAPPING],
                  ],
        [isCollapsed, isInverseColors],
    );

    return (
        <>
            <Box pos="absolute" top="0" left="0" w="0" h="0" ref={intersectionRef} />
            <Box
                opacity={isOpen ? 1 : 0}
                position="fixed"
                width="full"
                height="full"
                zIndex={isOpen ? 3 : -1}
                transition="0.5s"
                bg="blackAlpha.700"
                backdropFilter="blur(8px)">
                <Box position="relative" width="full" height="full">
                    <IconButton
                        icon={<CloseIcon w={3} h={3} />}
                        position="absolute"
                        top="2"
                        right="2"
                        variant="unstyled"
                        color="white"
                        aria-label="Close"
                        onClick={onToggle}
                    />
                    <Flex width="full" height="full" alignItems="center" justifyContent="center">
                        <MobileNav navItems={navItems} />
                    </Flex>
                </Box>
            </Box>
            <Flex
                opacity={isOpen ? 0 : 1}
                zIndex={isOpen ? -1 : 3}
                as="header"
                justifyContent="center"
                position="fixed"
                width="full"
                bg={backgroundColor}
                {...headerStyleProps}>
                <Flex
                    color={useColorModeValue('gray.600', 'white')}
                    h={isCollapsed ? HEADER_COLLAPSED_HEIGHT : HEADER_HEIGHT}
                    py={[1, 0]}
                    px={[0, 4]}
                    transition="0.5s"
                    width="90"
                    justifyContent="space-between"
                    alignItems="center">
                    <AnchorLink to="/">
                        <Logo isCollapsed={isCollapsed} isInverseColors={isInverseColors} {...logoProps} />
                    </AnchorLink>
                    <Flex alignItems="center">
                        <Flex display={['none', 'none', 'flex']} alignItems="center" ml={10}>
                            <DesktopNav
                                navItems={navItems}
                                isCollapsed={isCollapsed}
                                isInverseColors={isInverseColors}
                            />
                        </Flex>
                        <Box ml={['2', '5']}>
                            <IconButton
                                onClick={onToggle}
                                icon={
                                    <HamburgerIcon
                                        w={5}
                                        h={5}
                                        color={
                                            isCollapsed
                                                ? 'gray.600'
                                                : ['gray.600', 'gray.600', isInverseColors ? 'gray.600' : 'white']
                                        }
                                    />
                                }
                                variant="unstyled"
                                aria-label="Toggle Navigation"
                            />
                        </Box>
                        <Box mt="1" ml={['2', '5']}>
                            <LanguageSwitch />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default Header;
