import { NavItem } from '@/components/common/Header/Header.types';

export const NAV_ITEMS_LAND: NavItem[] = [
    {
        label: 'land.aboutTheProject',
        page: '/land',
        children: [
            {
                label: 'land.location.title',
                subLabel: 'land.location.subTitle',
                href: '/land#location',
            },
            {
                label: 'land.news.title',
                subLabel: 'land.news.subTitle',
                href: '/land#news',
            },
            {
                label: 'land.parameters.titleLeft',
                subLabel: 'land.parameters.subTitleLeft',
                href: '/land#parameters',
            },
            {
                label: 'land.parameters.titleRight',
                subLabel: 'land.parameters.subTitleRight',
                href: '/land#parameters',
            },
            {
                label: 'land.features.title',
                href: '/land#features',
            },
            {
                label: 'land.expenses.title',
                subLabel: 'land.expenses.subTitle',
                href: '/land#expenses',
            },
        ],
    },
    {
        label: 'land.availableProperties',
        href: '/land/scheme',
    },
    {
        label: 'land.details.title',
        page: '/land/details',
        children: [
            {
                label: 'land.details.projects.title',
                subLabel: 'land.details.projects.subTitle',
                href: '/land/details#projects',
            },
            {
                label: 'land.details.nextSteps.title',
                subLabel: 'land.details.nextSteps.subTitle',
                href: '/land/details#additional',
            },
            {
                label: 'land.details.buildingService.title',
                subLabel: 'land.details.buildingService.subTitle',
                href: '/land/details#building-service',
            },
        ],
    },
    {
        label: 'gallery.title',
        href: '/gallery?projectType=land',
    },
];
