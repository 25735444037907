import { graphql, useStaticQuery } from 'gatsby';

export type SiteMetadata = {
    title: string;
    description: string;
    type: string;
    keywords: string;
    author: string;
    url: string;
};

export const useSiteMetadata = () => {
    const {
        site: { siteMetadata },
    } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    description
                    type
                    keywords
                    author
                    url
                }
            }
        }
    `);
    return siteMetadata as SiteMetadata;
};

export default useSiteMetadata;
