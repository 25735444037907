import { FC } from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { GalleryImageGridProps } from './Gallery.types';
import GalleryImage from './GalleryImage/GalleryImage';

const GalleryImageGrid: FC<GalleryImageGridProps> = ({ images, ...boxProps }) => (
    <SimpleGrid columns={[2, 3, 4, 3]} spacing={5} {...boxProps}>
        {images.map(image => (
            <GalleryImage key={`gallery-grid-${image.id}`} imageData={image} />
        ))}
    </SimpleGrid>
);

export default GalleryImageGrid;
