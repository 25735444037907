import { FC } from 'react';

import { Box, Button, Flex } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { GalleryPeriods } from '@/enums';
import { galleryPeriodAtom } from '@/state/gallery/period';

import { GalleryGroupingProps } from './GalleryGrouping.types';

const GalleryGrouping: FC<GalleryGroupingProps> = ({ ...boxProps }) => {
    const { t } = useTranslation();
    const galleryPeriods = Object.values(GalleryPeriods);
    const [galleryPeriod, setGalleryPeriod] = useAtom(galleryPeriodAtom);

    const handleOnClick = (period: GalleryPeriods) => () => {
        setGalleryPeriod(period);
    };

    return (
        <Box {...boxProps}>
            <Flex flexWrap="wrap">
                {galleryPeriods.map(period => {
                    const isSelected = period === galleryPeriod;
                    return (
                        <Button
                            key={`gallery-period-select-${period}`}
                            onClick={handleOnClick(period)}
                            px={4}
                            py={2}
                            m={2}
                            borderRadius="3xl"
                            shadow="md"
                            cursor="pointer"
                            bg={isSelected ? 'gray.200' : 'gray.100'}>
                            {t(`gallery.periods.${period}`)}
                        </Button>
                    );
                })}
            </Flex>
        </Box>
    );
};

export default GalleryGrouping;
