import { FC, memo } from 'react';

import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ProjectTypes } from '@/enums';
import useGroupedGallery from '@/hooks/useGroupedGallery';

import { GalleryProps } from './Gallery.types';
import GalleryGrouping from './GalleryGrouping/GalleryGrouping';
import GalleryImageGrid from './GalleryImageGrid';
import GalleryPeriodTitle from './GalleryPeriodTitle';

const Gallery: FC<GalleryProps> = ({ selected = ProjectTypes.ALL, ...boxProps }) => {
    const { t } = useTranslation();
    const projectTypes = [ProjectTypes.ALL, ProjectTypes.LAND, ProjectTypes.HOUSE];
    const defaultIndex = projectTypes.indexOf(selected);
    const galleryImages = useGroupedGallery();

    return (
        <Box {...boxProps} justifyContent="flex-end">
            <Flex my={[3, 0]} justifyContent={['center', 'flex-end']}>
                <GalleryGrouping />
            </Flex>
            <Tabs variant="line" colorScheme="orange" defaultIndex={defaultIndex} isLazy>
                <TabList>
                    {projectTypes.map(projectType => (
                        <Tab key={`gallet-tab-title-${projectType}`}>{t(`${projectType}.name`)}</Tab>
                    ))}
                </TabList>

                <TabPanels>
                    {projectTypes.map(projectType => (
                        <TabPanel key={`gallery-tab-${projectType}`}>
                            {Object.entries(galleryImages[projectType]).map(([key, images]) => (
                                <Box mb={5} key={`gallery-project-${projectType}-period-${key}`} mx={-2}>
                                    <GalleryPeriodTitle>{key}</GalleryPeriodTitle>
                                    <GalleryImageGrid images={images} />
                                </Box>
                            ))}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default memo(Gallery);
