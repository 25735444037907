import type { GatsbyBrowser } from 'gatsby';
import { Provider } from 'jotai';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

// @ts-ignore
import { ModalWrapper, DrawerProvider } from '@/components/v2';
// @ts-ignore
import i18next from '@/utils/i18next';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/scrollbar';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => <Provider>{element}</Provider>;

export const replaceHydrateFunction: GatsbyBrowser['replaceHydrateFunction'] = () => (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
    <I18nextProvider i18n={i18next}>
        <ModalWrapper>
            <DrawerProvider>{element}</DrawerProvider>
        </ModalWrapper>
    </I18nextProvider>
);
