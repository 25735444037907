import { FC, useContext } from 'react';

import {
    Icon,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from '@chakra-ui/react';
import { MdLocationPin } from 'react-icons/md';
import { SiGooglemaps } from 'react-icons/si';
import { _MapContext } from 'react-map-gl';

import { MapMarkerProps } from './MapMarkert.types';

const MapMarker: FC<MapMarkerProps> = ({ longitude, latitude }) => {
    const context = useContext(_MapContext);

    const [x, y] = context.viewport?.project([longitude, latitude]) as [number, number];

    return (
        <Popover>
            <PopoverTrigger>
                <Icon
                    as={MdLocationPin}
                    color={'orange.300'}
                    w={10}
                    h={10}
                    top={y}
                    left={x}
                    position={'absolute'}
                />
            </PopoverTrigger>
            <PopoverContent top={y + 50} left={x - 135} position={'absolute'}>
                <PopoverArrow />
                <PopoverBody>
                    <VStack>
                        <Text>Локация на обекта</Text>
                        <Link href={`https://maps.google.com/?q=${latitude},${longitude}`} target={'_blank'}>
                            <Icon as={SiGooglemaps} mr={1} />
                            Виж на карта
                        </Link>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default MapMarker;
