import { useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useAtom } from 'jotai';

import { fourPropertiesAtom } from '@/state/four/fourProperties';
import { mapFrontmatter } from '@/utils/mapFrontmatter';

export type FourProperty = {
    size: number;
    price: number;
    number: number;
    statusText: string;
    coords: string;
    streetArea: string;
    modifiedTime: Date;
};

const useFourProperties = () => {
    const [stateProperties, setStateProperties] = useAtom(fourPropertiesAtom);
    const {
        properties: { nodes: properties },
    } = useStaticQuery(graphql`
        query {
            properties: allFile(filter: { sourceInstanceName: { eq: "four" } }) {
                nodes {
                    childMarkdownRemark {
                        frontmatter {
                            statusText
                            number
                            size
                            price
                            coords
                            streetArea
                        }
                    }
                    modifiedTime
                }
            }
        }
    `);
    const mappedProperties = mapFrontmatter<FourProperty[]>(properties).map(property => ({
        ...property,
        modifiedTime: new Date(property.modifiedTime),
    }));

    useEffect(() => {
        if (!stateProperties.length) {
            setStateProperties(mappedProperties);
        }
    }, [mappedProperties.length]);

    return [mappedProperties, stateProperties];
};

export default useFourProperties;
