import { FC } from 'react';

import { Box, ListItem, ListIcon, List, Text, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle } from 'react-icons/md';

import { Trans } from '@/components/common';
import { ContextKeys } from '@/enums';

import LandTitle from '../LandTitle/LandTitle';
import { LandParametersProps } from './LandParameters.types';

const LandParameters: FC<LandParametersProps> = ({ contextKey = ContextKeys.TWO, ...boxProps }) => {
    const { t } = useTranslation();

    return (
        <Box
            pos="relative"
            w="100%"
            h="100%"
            color="white"
            overflow="hidden"
            borderRadius="md"
            shadow="lg"
            {...boxProps}>
            <SimpleGrid columns={[1, 1, 2]} spacing={0}>
                <Box px={[4, 10]} py={[4, 42]} bg="gray.700">
                    <LandTitle mb={5}>{t(`${contextKey}.parameters.titleLeft`)}</LandTitle>
                    <List spacing={3}>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.200" boxSize={5} />
                            {t(`${contextKey}.parameters.parameters.buildHeight`)}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.200" boxSize={5} />
                            {t(`${contextKey}.parameters.parameters.buildDensity`)}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.200" boxSize={5} />
                            {t(`${contextKey}.parameters.parameters.buildIntensity`)}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.200" boxSize={5} />
                            {t(`${contextKey}.parameters.parameters.landscaping`)}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={MdCheckCircle} color="green.200" boxSize={5} />
                            {t(`${contextKey}.parameters.parameters.constructionMethod`)}
                        </ListItem>
                    </List>
                </Box>
                <Box px={[4, 10]} py={[4, 42]} bg="gray.100" color="gray.800">
                    <LandTitle mb={5}>{t(`${contextKey}.parameters.titleRight`)}</LandTitle>
                    <Text>
                        <Trans i18nKey={`${contextKey}.parameters.textRight`} />
                    </Text>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default LandParameters;
