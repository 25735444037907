import { FC } from 'react';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Stack, Icon, Link } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { NavItem } from '../Header.types';

const DesktopSubNav: FC<NavItem> = ({ label, href, subLabel }) => {
    const { t } = useTranslation();

    return (
        <Box role="group">
            <Link
                href={href}
                to={href as string}
                as={AnchorLink}
                display="block"
                p="3"
                rounded="md"
                _hover={{ fontWeight: 800 }}>
                <Stack direction="row" align="center">
                    <Flex
                        transition={'all .3s ease'}
                        transform={'translateX(-10px)'}
                        _groupHover={{
                            opacity: '100%',
                            transform: 'translateX(0)',
                        }}
                        align="start">
                        <Icon color={'gray.900'} w={5} h={5} as={ChevronRightIcon} />
                    </Flex>
                    <Box>
                        <Text
                            transition={'all .3s ease'}
                            _groupHover={{ fontWeight: '700' }}
                            color="gray.700"
                            fontWeight="500">
                            {t(label)}
                        </Text>
                        {subLabel && <Text fontSize={'sm'}>{t(subLabel)}</Text>}
                    </Box>
                </Stack>
            </Link>
        </Box>
    );
};

export default DesktopSubNav;
