import { FC, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';
import MapGL from 'react-map-gl';

import { ContextKeys } from '@/enums';

import { MapProps } from './Map.types';
import MapMarker from './MapMarker/MapMarker';

const MAPBOX_TOKEN = process.env.GATSBY_MAP_ENV;

const COORDS_MAP = {
    [ContextKeys.TWO]: {
        latitude: 42.594831918538176,
        longitude: 23.632187613752617,
    },
    [ContextKeys.FOUR]: {
        latitude: 42.601946,
        longitude: 23.566486,
    },
};

const Map: FC<MapProps> = ({ contextKey = ContextKeys.TWO, mapGlprops, ...boxProps }) => {
    const initialCoords = useMemo(() => COORDS_MAP[contextKey], [contextKey]);

    const [viewport, setViewport] = useState({
        ...initialCoords,
        zoom: 15,
    });

    return (
        <Box pos="relative" {...boxProps}>
            <MapGL
                {...viewport}
                width="100%"
                onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                mapStyle={'mapbox://styles/mapbox/satellite-v9'}
                {...mapGlprops}>
                <MapMarker latitude={initialCoords.latitude} longitude={initialCoords.longitude} />
            </MapGL>
        </Box>
    );
};

export default Map;
