import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { ProjectTypes } from '@/enums';
import { mapFrontmatter } from '@/utils/mapFrontmatter';

import useAssets from './useAssets';

export type GalleryNode = {
    image: string;
    date: Date;
    projectType: ProjectTypes;
    id: string;
};

export type GalleryItem = GalleryNode & {
    image: IGatsbyImageData;
};

const useGallery = () => {
    const assets = useAssets();
    const {
        gallery: { nodes: galleryNodes },
    } = useStaticQuery(graphql`
        {
            gallery: allFile(
                filter: { sourceInstanceName: { eq: "gallery" } }
                sort: { childrenMarkdownRemark: { frontmatter: { date: DESC } } }
            ) {
                nodes {
                    childMarkdownRemark {
                        frontmatter {
                            image
                            date
                            projectType
                        }
                        id
                    }
                }
            }
        }
    `);

    const images = mapFrontmatter<GalleryNode[]>(galleryNodes).map(galleryNode => ({
        ...galleryNode,
        image: assets.find(asset => asset.relativePath === galleryNode.image)?.imageData,
        date: new Date(galleryNode.date),
    })) as GalleryItem[];

    const imagesForProject = Object.fromEntries(
        Object.values(ProjectTypes).map(projectType => {
            const imagesForProject = images.filter(image => image.projectType === projectType);
            return [projectType, imagesForProject];
        }),
    ) as Record<ProjectTypes, GalleryItem[]>;

    return { ...imagesForProject, [ProjectTypes.ALL]: images };
};

export default useGallery;
