import { FC } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Flex, Text, Stack, Collapse, Icon, Link, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { NavItem } from '../Header.types';

const MobileNavItem: FC<NavItem> = ({ label, children, href }: NavItem) => {
    const { t } = useTranslation();
    const { isOpen, onToggle } = useDisclosure();
    const stackLineColor = useColorModeValue('gray.200', 'gray.700');
    const textColor = useColorModeValue('gray.600', 'gray.200');

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify="space-between"
                align="center"
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text fontWeight="light" color={textColor}>
                    {t(label)}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition="all .25s ease-in-out"
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack mt={2} pl={4} borderLeft={1} borderStyle="solid" borderColor={stackLineColor} align="start">
                    {children &&
                        children.map(child => (
                            <Link
                                key={child.label}
                                as={AnchorLink}
                                py={2}
                                href={child.href}
                                target={child.newTab ? '_blank' : '_self'}
                                to={child.href as string}>
                                <Text fontWeight="light" color={textColor}>
                                    {t(child.label)}
                                </Text>
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

export default MobileNavItem;
