import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import useSiteMetadata from '@/hooks/useSiteMetadata';

import { SEOProps } from './SEO.types';

const SEO: FC<SEOProps> = ({ title, titleKey }) => {
    const { t } = useTranslation();
    const siteMetadata = useSiteMetadata();
    const pageTitle = titleKey ? t(titleKey) : title || siteMetadata.title;

    return (
        <>
            <title>{pageTitle}</title>
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            {/* Facebook */}
            <meta property="og:url" content={siteMetadata.url} />
            <meta property="og:type" content={siteMetadata.type} />
            <meta property="og:title" content={siteMetadata.title} />
            <meta property="og:description" content={`${siteMetadata.title} - ${siteMetadata.description}`} />
            <meta property="og:image" content={`${siteMetadata.url}/thumbnail.png`} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={siteMetadata.url} />
            <meta name="twitter:title" content={siteMetadata.title} />
            <meta name="twitter:description" content={siteMetadata.description} />
            <meta name="twitter:image" content={`${siteMetadata.url}/thumbnail.png`} />
        </>
    );
};

export default SEO;
