import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const LanguageSwitch: FC = () => {
    const { i18n } = useTranslation();

    const changeLanguage = () => {
        i18n.changeLanguage(i18n.language === 'en' ? 'bg' : 'en');
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            transition="0.5s"
            cursor="pointer"
            width="7"
            height="5"
            onClick={changeLanguage}>
            {i18n.language === 'en' ? (
                <StaticImage src="../../../images/bulgarian.png" alt="На Български" />
            ) : (
                <StaticImage src="../../../images/english.png" alt="English Description" />
            )}
        </Box>
    );
};

export default LanguageSwitch;
