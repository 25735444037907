import { FC, useMemo } from 'react';

import {
    Box,
    Heading,
    Text,
    Button,
    VStack,
    HStack,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    useColorModeValue,
    Divider,
    useToast,
    Grid,
    GridItem,
    Flex,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useAtom } from 'jotai';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';

import { FourPropertiesScheme } from '@/components/four';
import { LandPropertiesScheme } from '@/components/land';
import { EMAIL_REGEX, PHONE_REGEX } from '@/constants/regex';
import { ContextKeys } from '@/enums';
import { queriedPropertiesAtom } from '@/state/land/properties';
import { formatPrice } from '@/utils/formatPrice';
import { formatNumber } from '@/utils/four/formatNumber';

import { QueryFormProps } from './QueryForm.types';

const SCHEME_COMPONENT_MAPPING = {
    [ContextKeys.TWO]: LandPropertiesScheme,
    [ContextKeys.FOUR]: FourPropertiesScheme,
};

const validate = (values: Partial<any>) => {
    const errors: Partial<any> = {};
    if (!values.email) {
        errors.email = 'Required';
    } else if (!EMAIL_REGEX.test(values.email)) {
        errors.email = 'Невалиден E-Mail адрес';
    }
    if (!values.telephone) {
        errors.telephone = 'Required';
    } else if (!PHONE_REGEX.test(values.telephone)) {
        errors.telephone = 'Невалиден телефонен номер';
    }

    return errors;
};

const QueryForm: FC<QueryFormProps> = ({ propertyData, contextKey = ContextKeys.TWO, ...boxProps }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const [queriedProperties, setQueriedProperties] = useAtom(queriedPropertiesAtom);
    const SchemeComponent = useMemo(() => SCHEME_COMPONENT_MAPPING[contextKey], [contextKey]);
    const hasQueried = queriedProperties.includes(propertyData.number);

    const handleOnSuccess = () => {
        toast({
            description: t('common.formInput.toastSuccess'),
            status: 'success',
            duration: 9000,
            position: 'bottom',
        });
        setQueriedProperties(queriedProperties => [...queriedProperties, propertyData.number]);
    };

    return (
        <Box
            p={4}
            bg={useColorModeValue('white', 'gray.800')}
            color={useColorModeValue('gray.800', 'gray.500')}
            rounded="md"
            shadow="lg"
            {...boxProps}>
            <Grid gap={5} templateColumns="repeat(3, 1fr)">
                <GridItem colSpan={[3, 1]}>
                    <Box w="full">
                        <Heading as="h1" fontWeight="semibold" fontSize="xl">
                            {t('land.query.title', {
                                number:
                                    contextKey === ContextKeys.FOUR
                                        ? formatNumber(propertyData.number)
                                        : propertyData.number,
                            })}
                        </Heading>
                        <SchemeComponent highlightedProperty={propertyData} my="2" />
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                            <HStack mt={6} fontSize="lg">
                                <Text color="gray.700" fontWeight={100}>
                                    {t('filters.size')}
                                </Text>
                                <Text color="green.800" fontWeight={700}>
                                    {propertyData.size}
                                </Text>
                                <Text fontSize="sm">{t('common.sqm')}</Text>
                            </HStack>
                            <Divider />
                            {contextKey === ContextKeys.TWO && (
                                <Heading as="h2" fontWeight={300} fontSize="lg">
                                    {t('land.scheme.singlePayment')}
                                </Heading>
                            )}
                            <HStack mt={6} fontSize="lg">
                                <Text color="gray.700" fontWeight={100}>
                                    {t('filters.price')}
                                </Text>
                                <Text color="green.800" fontWeight={700}>
                                    {formatPrice(propertyData.price)}
                                </Text>
                                <Text fontSize="sm">{t('common.withVat')}</Text>
                            </HStack>
                            {contextKey === ContextKeys.TWO && (
                                <>
                                    <Divider />
                                    <Heading as="h2" fontWeight={300} fontSize="lg">
                                        {t('land.scheme.multiplePayments')}
                                    </Heading>
                                    <HStack fontSize="lg">
                                        <Text color="gray.700" fontWeight={100}>
                                            {t('land.scheme.headers.firstPayment')}
                                        </Text>
                                        <Text color="green.800" fontWeight={700}>
                                            {formatPrice(propertyData.priceFirst)}
                                        </Text>
                                        <Text fontSize="sm">{t('common.withVat')}</Text>
                                    </HStack>
                                    <HStack fontSize="lg">
                                        <Text color="gray.700" fontWeight={100}>
                                            {t('land.scheme.headers.secondPayment')}
                                        </Text>
                                        <Text color="green.800" fontWeight={700}>
                                            {formatPrice(propertyData.priceSecond)}
                                        </Text>
                                        <Text fontSize="sm">{t('common.withVat')}</Text>
                                    </HStack>
                                </>
                            )}
                        </VStack>
                    </Box>
                </GridItem>
                <GridItem colSpan={[3, 2]}>
                    {hasQueried ? (
                        <Flex
                            flex={1}
                            alignItems="center"
                            justifyContent="center"
                            px={4}
                            py={2}
                            bg="green.100"
                            borderRadius="md">
                            <Text color="gray.700" fontWeight="thin">
                                {t('common.formInput.messageSuccess')}
                            </Text>
                        </Flex>
                    ) : (
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                telephone: '',
                                message: '',
                                number: propertyData.number,
                                'form-name': 'query-property',
                            }}
                            validate={validate}
                            onSubmit={(values, actions) => {
                                fetch('/', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    },
                                    body: qs.stringify(values),
                                })
                                    .then(() => {
                                        actions.resetForm();
                                        handleOnSuccess();
                                    })
                                    .catch(error => alert(error));
                            }}>
                            {({ handleChange, handleSubmit, isSubmitting, errors, values }) => (
                                <form
                                    name="query-property"
                                    method="post"
                                    // eslint-disable-next-line react/no-unknown-property
                                    netlify-honeypot="bot-field"
                                    data-netlify="true"
                                    onSubmit={handleSubmit}>
                                    <Input hidden name="bot-field" />
                                    <Input hidden name="form-name" value={values['form-name']} />
                                    <Input hidden name="number" value={values.number} />
                                    <VStack spacing={5} w="full">
                                        <FormControl id="name" w="full">
                                            <FormLabel>{t('common.formInput.name')}</FormLabel>
                                            <InputGroup borderColor="#E0E1E7" w="full">
                                                <InputLeftElement pointerEvents="none">
                                                    <BsPerson color="gray.800" />
                                                </InputLeftElement>
                                                <Input
                                                    type="text"
                                                    name="name"
                                                    size="md"
                                                    onChange={handleChange}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl id="email">
                                            <FormLabel>{t('common.formInput.email')}</FormLabel>
                                            <InputGroup borderColor="#E0E1E7">
                                                <InputLeftElement pointerEvents="none">
                                                    <MdOutlineEmail color="gray.800" />
                                                </InputLeftElement>
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    size="md"
                                                    isInvalid={!!errors.email}
                                                    onChange={handleChange}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl id="telephone">
                                            <FormLabel>{t('common.formInput.phone')}</FormLabel>
                                            <InputGroup borderColor="#E0E1E7">
                                                <InputLeftElement pointerEvents="none">
                                                    <MdOutlinePhone color="gray.800" />
                                                </InputLeftElement>
                                                <Input
                                                    type="tel"
                                                    name="telephone"
                                                    size="md"
                                                    isInvalid={!!errors.telephone}
                                                    onChange={handleChange}
                                                    disabled={isSubmitting}
                                                    required
                                                />
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl id="message">
                                            <FormLabel>{t('common.formInput.message')}</FormLabel>
                                            <Textarea
                                                borderColor="gray.300"
                                                _hover={{
                                                    borderColor: 'gray.300',
                                                }}
                                                placeholder={t('common.formInput.message')}
                                                name="message"
                                                onChange={handleChange}
                                                disabled={isSubmitting}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl id="submit" float="right">
                                            <Button
                                                variant="solid"
                                                colorScheme="orange"
                                                type="submit"
                                                disabled={isSubmitting}>
                                                {t('common.formInput.sendQuery')}
                                            </Button>
                                        </FormControl>
                                    </VStack>
                                </form>
                            )}
                        </Formik>
                    )}
                </GridItem>
            </Grid>
        </Box>
    );
};

export default QueryForm;
