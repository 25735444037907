import { FC } from 'react';

import {
    Button,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import * as Gatsby from 'gatsby';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { EStatusIcon } from '@/enums';
import { selectedPropetyAtom } from '@/state/land/properties';
import { formatPrice } from '@/utils/formatPrice';

import { LandPropertyModalProps } from './LandPropertyModal.types';

const LandPropertyModal: FC<LandPropertyModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [selectedProperty, setSelectedProperty] = useAtom(selectedPropetyAtom);

    const handleClosePopup = () => {
        setSelectedProperty(null);
        onClose();
    };

    return (
        selectedProperty && (
            <Modal isOpen={isOpen} onClose={handleClosePopup} size="md">
                <ModalOverlay />
                <ModalContent alignSelf="center" justifyContent="center" position="absolute">
                    <ModalHeader>
                        {`
            ${t('common.number')} ${selectedProperty.number} - ${selectedProperty.size} ${t('common.sqm')}`}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>{t('land.scheme.singlePayment')}</Th>
                                    <Th>{t('common.withVat')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>{t('filters.price')}</Td>
                                    <Td>{formatPrice(selectedProperty.price)}</Td>
                                </Tr>
                            </Tbody>
                            <Thead>
                                <Tr>
                                    <Th>{t('land.scheme.multiplePayments')}</Th>
                                    <Th>{t('common.withVat')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>{t('land.scheme.headers.firstPayment')}</Td>
                                    <Td>{formatPrice(selectedProperty.priceFirst)}</Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('land.scheme.headers.secondPayment')}</Td>
                                    <Td>{formatPrice(selectedProperty.priceSecond)}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                        {selectedProperty.statusIcon && selectedProperty.statusIcon === EStatusIcon.GREEN && (
                            <Link as={Gatsby.Link} to={`/query/${selectedProperty.number}`}>
                                <Button colorScheme="green" w="full" mt={4}>
                                    {t('common.query')}
                                </Button>
                            </Link>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    );
};

export default LandPropertyModal;
