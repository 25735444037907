import { FC } from 'react';

import { Box, Container, SimpleGrid, Stack, Text, Flex, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { NAV_ITEMS_FOUR } from '@/constants/four/navigation';
import { NAV_ITEMS_LAND } from '@/constants/land/navigation';
import useLastChanges from '@/hooks/useLastChanges';

import { Logo } from '../';
import { FooterProps } from './Footer.types';
import FooterListHeader from './FooterListHeader';
import FooterListSubHeader from './FooterListSubHeader';
import FooterNavMap from './FooterNavMap';

const Footer: FC<FooterProps> = ({ ...boxProps }) => {
    const { t } = useTranslation();
    const lastChanges = useLastChanges();
    return (
        <Box color="gray.700" {...boxProps}>
            <Container as={Stack} maxW="container.lg">
                <SimpleGrid columns={[1, 3, 3]} spacing={8} px={8}>
                    <FooterNavMap navItems={NAV_ITEMS_FOUR} title={t('four.name')} />
                    <FooterNavMap navItems={NAV_ITEMS_LAND} title={t('land.name')} />
                    <Stack>
                        <FooterListHeader>{t('common.contact.title')}</FooterListHeader>
                        <Box>
                            <FooterListSubHeader>{t('common.contact.company')}</FooterListSubHeader>
                            <FooterListSubHeader>{t('common.contact.companyNumber')}</FooterListSubHeader>
                            <FooterListSubHeader>{t('common.contact.address')}</FooterListSubHeader>
                            <FooterListSubHeader>{t('common.contact.phone')}</FooterListSubHeader>
                            <FooterListSubHeader>{t('common.contact.email')}</FooterListSubHeader>
                        </Box>
                    </Stack>
                </SimpleGrid>
            </Container>
            <Box py={10}>
                <Flex
                    align="center"
                    _before={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: 'gray.200',
                        flexGrow: 1,
                        mr: 8,
                    }}
                    _after={{
                        content: '""',
                        borderBottom: '1px solid',
                        borderColor: 'gray.200',
                        flexGrow: 1,
                        ml: 8,
                    }}>
                    <Logo isCollapsed />
                </Flex>
                <VStack>
                    <Text pt={6} fontSize={'sm'} textAlign={'center'}>
                        © {t('common.copyright', { year: format(Date.now(), 'yyyy') })}
                    </Text>
                    <Text pt={6} fontSize="xs" textAlign="center">
                        {`${t('common.lastChange')}: ${lastChanges}`}
                    </Text>
                </VStack>
            </Box>
        </Box>
    );
};

export default Footer;
