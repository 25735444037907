import { BoxProps } from '@chakra-ui/react';

import { FourPropertiesScheme, FourPropertiesTable, FourPropertyDrawer } from '@/components/four';
import { LandPropertiesScheme, LandPropertiesTable, LandPropertyDrawer } from '@/components/land';
import { ContextKeys, DrawerTypes } from '@/enums';

import { FEATURES_FOUR } from './four/features';
import { NAV_ITEMS_FOUR } from './four/navigation';
import { FEATURES_LAND } from './land/features';
import { NAV_ITEMS_LAND } from './land/navigation';

export const IS_BROWSER = typeof window !== 'undefined';

export const SECTION_SPACING: BoxProps['m'] = 10;

export const SCHEME_COMPONENT_MAPPING = {
    [ContextKeys.TWO]: LandPropertiesScheme,
    [ContextKeys.FOUR]: FourPropertiesScheme,
};

export const TABLE_COMPONENT_MAPPING = {
    [ContextKeys.TWO]: LandPropertiesTable,
    [ContextKeys.FOUR]: FourPropertiesTable,
};

export const SLUG_MAPPING = {
    [ContextKeys.TWO]: '/land',
    [ContextKeys.FOUR]: '/2024',
};

export const NAV_ITEMS_MAPPING = {
    [ContextKeys.TWO]: NAV_ITEMS_LAND,
    [ContextKeys.FOUR]: NAV_ITEMS_FOUR,
};

export const YEAR_MAPPING = {
    [ContextKeys.TWO]: "'22",
    [ContextKeys.FOUR]: "'24",
};

export const FEATURES_MAPPING = {
    [ContextKeys.TWO]: FEATURES_LAND,
    [ContextKeys.FOUR]: FEATURES_FOUR,
};

export const DRAWER_MAPPING = {
    [DrawerTypes.FOUR_PROPERTY]: FourPropertyDrawer,
    [DrawerTypes.TWO_PROPERTY]: LandPropertyDrawer,
};

export const DRAWER_TYPES_MAPPING = {
    [ContextKeys.TWO]: DrawerTypes.TWO_PROPERTY,
    [ContextKeys.FOUR]: DrawerTypes.FOUR_PROPERTY,
};
