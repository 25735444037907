import { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { VTimelineProps } from './VTimeline.types';

const VTimeline: FC<VTimelineProps> = ({ children, ...boxProps }) => (
    <Box {...boxProps}>
        {children &&
            children.map((child, i) => (
                <Flex key={`vertical-timeline-${i}`} role="group" cursor="pointer">
                    <Box w="80%" py="10">
                        {child}
                    </Box>
                    <Flex w="20%" justifyContent="flex-end" pr="4">
                        <Flex
                            w="0.5"
                            bgGradient={
                                i === 0
                                    ? 'linear(to-b, transparent 50%, gray.200 50%)'
                                    : i === children.length - 1
                                    ? 'linear(to-b, gray.200 50%, transparent 50%)'
                                    : 'linear(to-b, gray.200, gray.200)'
                            }
                            position="relative"
                            justifyContent="center"
                            alignItems="center">
                            <Box
                                position="absolute"
                                w="10"
                                h="10"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bg="gray.100"
                                borderRadius="full"
                                _groupHover={{ background: 'gray.300' }}
                                transition="0.5s">
                                <Text fontWeight="light" color="gray.700">
                                    {i + 1}
                                </Text>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
            ))}
    </Box>
);

export default VTimeline;
