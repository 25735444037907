import { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useAtomValue } from 'jotai';

import { ProjectTypes } from '@/enums';
import { galleryPeriodAtom } from '@/state/gallery/period';
import { groupByPeriod } from '@/utils/groupByPeriod';
import { mapFrontmatter } from '@/utils/mapFrontmatter';

import useAssets from './useAssets';
import { GalleryItem, GalleryNode } from './useGallery';

const useGroupedGallery = () => {
    const assets = useAssets();
    const {
        gallery: { nodes: galleryNodes },
    } = useStaticQuery(graphql`
        {
            gallery: allFile(
                filter: { sourceInstanceName: { eq: "gallery" } }
                sort: { childrenMarkdownRemark: { frontmatter: { date: DESC } } }
            ) {
                nodes {
                    childMarkdownRemark {
                        frontmatter {
                            image
                            date
                            projectType
                        }
                        id
                    }
                }
            }
        }
    `);

    const galleryPeriod = useAtomValue(galleryPeriodAtom);

    const images = mapFrontmatter<GalleryNode[]>(galleryNodes).map(galleryNode => ({
        ...galleryNode,
        image: assets.find(asset => asset.relativePath === galleryNode.image)?.imageData,
        date: new Date(galleryNode.date),
    })) as GalleryItem[];

    const { groupedImages, ...groupedProjectTypeImages } = useMemo(() => {
        const groupedImages = groupByPeriod<GalleryItem>(images, 'date', galleryPeriod);

        const groupedProjectTypeImages = Object.fromEntries(
            Object.values(ProjectTypes).map(projectType => [
                projectType,
                Object.fromEntries(
                    Object.entries(groupedImages).reduce((prev: [string, GalleryItem[]][], [key, images]) => {
                        const imagesForProject = images.filter(image => image.projectType === projectType);
                        if (imagesForProject.length) {
                            return [...prev, [key, imagesForProject]];
                        }
                        return prev;
                    }, []),
                ),
            ]),
        ) as unknown as Record<ProjectTypes, Record<string, GalleryItem[]>>;

        return { groupedImages, ...groupedProjectTypeImages };
    }, [images, galleryPeriod]);

    return { ...groupedProjectTypeImages, [ProjectTypes.ALL]: groupedImages };
};

export default useGroupedGallery;
