import { FC } from 'react';

import { Box, Text, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { chunk } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Carousel } from '@/components/common';
import { ProjectTypes } from '@/enums';
import useGallery from '@/hooks/useGallery';

import GalleryImage from '../GalleryImage/GalleryImage';
import { LastGalleryProps } from './LastGallery.types';

const CAROUSEL_BREAKPOINTS = {
    0: {
        slidesPerView: 1.2,
    },
    250: {
        slidesPerView: 1.2,
    },
    500: {
        slidesPerView: 2.5,
    },
    750: {
        slidesPerView: 4.5,
    },
    1000: {
        slidesPerView: 3.5,
    },
};

const LastGallery: FC<LastGalleryProps> = ({
    projectType = ProjectTypes.ALL,
    breakpoints = CAROUSEL_BREAKPOINTS,
    imageNum = 10,
    linkProps,
    ...boxProps
}) => {
    const { t } = useTranslation();
    const allImages = useGallery();
    const images = chunk(allImages[projectType], 2);
    const groupedProjectImages = images.slice(0, imageNum);

    return (
        <Box {...boxProps}>
            <Box w="full" h="full" mb={5} display="flex" justifyContent={['center', 'flex-end']}>
                <Link as={GatsbyLink} to={`/gallery?projectType=${projectType}`} fontSize="lg" {...linkProps}>
                    <Text color="gray.700" fontWeight="thin">
                        {t('gallery.title')} - {t(`${projectType}.name`)}
                    </Text>
                </Link>
            </Box>
            <Carousel breakpoints={breakpoints} scrollbar={false} spaceBetween={20}>
                {groupedProjectImages.map(([imageOne, imageTwo], index) => {
                    const key = `landing-gallery-${imageOne.projectType}-${imageOne.id}-${
                        imageTwo ? `${imageTwo.projectType}-${imageTwo.id}` : ''
                    }`;
                    const ml = index === 0 ? 4 : 0;
                    return (
                        <Box key={key} ml={ml}>
                            <GalleryImage shadow="md" imageData={imageOne} showTitle={false} />
                            {imageTwo && (
                                <Box mt={3}>
                                    <GalleryImage shadow="md" imageData={imageTwo} showTitle={false} />
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Carousel>
        </Box>
    );
};

export default LastGallery;
