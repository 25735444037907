import { useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useAtom } from 'jotai';

import { EStatusIcon } from '@/enums';
import { propertiesAtom } from '@/state/land/properties';
import { mapFrontmatter } from '@/utils/mapFrontmatter';

export type Property = {
    size: number;
    price: number;
    priceFirst: number;
    priceSecond: number;
    number: number;
    status: boolean;
    statusText: string;
    statusIcon: EStatusIcon;
};

const useProperties = () => {
    const [stateProperties, setStateProperties] = useAtom(propertiesAtom);
    const {
        properties: { nodes: properties },
    } = useStaticQuery(graphql`
        query {
            properties: allFile(filter: { sourceInstanceName: { eq: "properties" } }) {
                nodes {
                    childMarkdownRemark {
                        frontmatter {
                            status
                            number
                            size
                            price
                            priceFirst
                            priceSecond
                            statusIcon
                            statusText
                        }
                    }
                }
            }
        }
    `);
    const mappedProperties = mapFrontmatter<Property[]>(properties);

    useEffect(() => {
        if (!stateProperties.length) {
            setStateProperties(mappedProperties);
        }
    }, [mappedProperties.length]);

    return [mappedProperties, stateProperties];
};

export default useProperties;
