import { memo, FC } from 'react';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselProps } from './Carousel.types';

const DEFAULT_BREAKPOINTS = {
    0: {
        slidesPerView: 1.1,
    },
    250: {
        slidesPerView: 1.5,
    },
    500: {
        slidesPerView: 2.2,
    },
    750: {
        slidesPerView: 2.8,
    },
    1000: {
        slidesPerView: 3.5,
    },
};

const Carousel: FC<CarouselProps> = ({ children, breakpoints = DEFAULT_BREAKPOINTS, ...carouselProps }) => (
    <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={12}
        scrollbar={{ draggable: true }}
        breakpoints={breakpoints}
        {...carouselProps}>
        {children?.length &&
            children.map((childItem: FC, index: number) => (
                <SwiperSlide
                    key={`carousel-${index}`}
                    style={{ height: 'auto', backgroundColor: 'transparent', boxShadow: 'none' }}>
                    {childItem}
                </SwiperSlide>
            ))}
    </Swiper>
);

export default memo(Carousel);
