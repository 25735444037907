import { FC } from 'react';

import { Box, Container } from '@chakra-ui/react';

import { Footer, GDPR } from '../';
import { HEADER_HEIGHT } from '../Header/Header';
import { LayoutProps } from './Layout.types';

const Layout: FC<LayoutProps> = ({ children, containerProps, ...boxProps }) => (
    <>
        <Box flex={1} bgGradient="linear(to-b, white, gray.100)" pos="relative" pt={HEADER_HEIGHT}>
            <Container maxW="container.lg" pt={6} pb={16} {...containerProps}>
                <Box as="main" {...boxProps}>
                    {children}
                </Box>
            </Container>
            <Footer bg="transparent" />
        </Box>
        <GDPR />
    </>
);
export default Layout;
