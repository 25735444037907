import { NavItem } from '@/components/common/Header/Header.types';

export const NAV_ITEMS_FOUR: NavItem[] = [
    {
        label: 'four.aboutTheProject',
        page: '/2024/',
        children: [
            {
                label: 'four.location.title',
                subLabel: 'four.location.subTitle',
                href: '/2024#location',
            },
            {
                label: 'four.status.title',
                href: '/2024#status',
            },
            {
                label: 'four.parameters.titleLeft',
                subLabel: 'four.parameters.subTitleLeft',
                href: '/2024#parameters',
            },
            {
                label: 'four.parameters.titleRight',
                subLabel: 'four.parameters.subTitleRight',
                href: '/2024#parameters',
            },
            {
                label: 'four.features.title',
                href: '/2024#features',
            },
            {
                label: 'four.procedure.title',
                href: '/2024#procedure',
            },
            {
                label: 'four.additional-investor.title',
                href: '/2024#additional-investor',
            },
            {
                label: 'four.expenses.title',
                subLabel: 'four.expenses.subTitle',
                href: '/2024#expenses',
            },
        ],
    },
    {
        label: 'four.availableProperties',
        href: '/2024/scheme/',
    },
    {
        label: 'four.details.nextSteps.title',
        href: '/2024/details/',
    },
    {
        label: 'four.documents.history.title',
        subLabel: 'four.documents.history.subTitle',
        href: 'https://pimprima-my.sharepoint.com/:f:/g/personal/milko_belev_pimprima_eu/Eqg_Q0I953RJpme5EatUjPkB025Tq8TX78oFmqA1hcLmvw?e=obhl1n',
        hideLanding: true,
    },
    {
        label: 'four.documents.buyers.title',
        subLabel: 'four.documents.buyers.subTitle',
        href: 'https://pimprima-my.sharepoint.com/:f:/g/personal/milko_belev_pimprima_eu/ErnuVfssjRZJrdROHgTwjx0BM9EiCIAg8G5DaM7c3WLQbQ?e=PiqKcX',
        hideLanding: true,
    },
];
