import { FC } from 'react';

import { Box, Highlight, Icon, Text } from '@chakra-ui/react';
import { FaLeaf } from 'react-icons/fa';

import { LogoProps } from './Logo.types';

const Logo: FC<LogoProps> = ({ ...boxProps }) => (
    <Box pos="relative" {...boxProps}>
        <Icon as={FaLeaf} color="green.100" pos="absolute" w={[25, 50]} h={[25, 50]} left={[-2.5, -5]} top={0} />
        <Text
            pos="relative"
            fontWeight="hairline"
            fontSize={['xl', '3xl']}
            textTransform="uppercase"
            fontFamily={'heading'}
            color="gray.500"
            whiteSpace="nowrap">
            <Highlight query="Nature" styles={{ color: 'green.500' }}>
                BackToNature
            </Highlight>
        </Text>
    </Box>
);

export default Logo;
