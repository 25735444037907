import { Box } from '@chakra-ui/react';

import { Carousel } from '@/components/common';
import useNews from '@/hooks/land/useNews';

import LandNewsItem from './LandNewsItem/LandNewsItem';

const LandNews = () => {
    const news = useNews();

    return (
        <Box w="full">
            <Carousel
                breakpoints={{
                    0: {
                        slidesPerView: 1.1,
                    },
                    250: {
                        slidesPerView: 1.1,
                    },
                    500: {
                        slidesPerView: 2.1,
                    },
                    750: {
                        slidesPerView: 2.8,
                    },
                    1000: {
                        slidesPerView: 3.5,
                    },
                }}
                style={{ paddingBottom: '15px' }}>
                {news.map(newsItem => (
                    <LandNewsItem key={`land-news-item-${newsItem.id}`} newsItem={newsItem} />
                ))}
            </Carousel>
        </Box>
    );
};

export default LandNews;
