import { FC } from 'react';

import { ChakraTheme, BoxProps } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import CookieConsent from 'react-cookie-consent';

export const GDPR: FC<BoxProps> = () => {
    const theme = useTheme() as ChakraTheme;
    const colors = theme.colors as any;
    const radii = theme.radii as any;

    return (
        <CookieConsent
            location="bottom"
            buttonText="Разбирам"
            style={{ backgroundColor: colors.whiteAlpha[800], color: colors.gray[700] }}
            declineButtonText="Отказвам"
            buttonStyle={{
                borderRadius: radii.md,
                color: colors.green[800],
                backgroundColor: colors.green[300],
            }}
            declineButtonStyle={{
                borderRadius: radii.md,
                color: colors.red[700],
                backgroundColor: colors.red[300],
            }}
            cookieName="gatsby-gdpr-google-analytics"
            enableDeclineButton>
            Този сайт използва бисквитки, за да подобри потребителското преживяване.
        </CookieConsent>
    );
};

export default GDPR;
