import { FC } from 'react';

import { Box, ListItem, UnorderedList } from '@chakra-ui/react';

import { Trans, VTimeline } from '@/components/common';
import { ContextKeys } from '@/enums';

import { LandAdditionalProps } from './LandAdditional.types';
import LandAdditionalStep from './LandAdditionalStep/LandAdditionalStep';

const LandAdditional: FC<LandAdditionalProps> = ({ contextKey = ContextKeys.TWO, ...boxProps }) => (
    <Box {...boxProps}>
        <Trans i18nKey={`${contextKey}.details.nextSteps.engage`} />
        <VTimeline>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.1`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.2`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.3`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.4`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.5`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.6`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.engageTwo`} />
                <UnorderedList>
                    <ListItem>
                        <Trans i18nKey={`${contextKey}.details.nextSteps.steps.7`} />
                    </ListItem>
                    <ListItem>
                        <Trans i18nKey={`${contextKey}.details.nextSteps.steps.8`} />
                    </ListItem>
                    <ListItem>
                        <Trans i18nKey={`${contextKey}.details.nextSteps.steps.9`} />
                    </ListItem>
                    <ListItem>
                        <Trans i18nKey={`${contextKey}.details.nextSteps.steps.10`} />
                    </ListItem>
                </UnorderedList>
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.11`} />
            </LandAdditionalStep>
            <LandAdditionalStep>
                <Trans i18nKey={`${contextKey}.details.nextSteps.steps.12`} />
            </LandAdditionalStep>
        </VTimeline>
    </Box>
);

export default LandAdditional;
