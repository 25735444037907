import { FC } from 'react';

import { Box, Flex, Link } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { FourMapProps } from './FourMap.types';

const FourMap: FC<FourMapProps> = () => {
    const { t } = useTranslation();

    return (
        <Link
            href="https://www.google.com/maps/dir/42.6056851,23.5671009/MAG+Italia,+%D0%B1%D1%83%D0%BB.+%E2%80%9E%D0%A6%D0%B0%D1%80%D0%B8%D0%B3%D1%80%D0%B0%D0%B4%D1%81%D0%BA%D0%BE+%D1%88%D0%BE%D1%81%D0%B5%E2%80%9C+425,+1186+%D0%A1%D0%BE%D1%84%D0%B8%D1%8F/@42.6190397,23.4878677,12.99z/data=!4m9!4m8!1m0!1m5!1m1!1s0x40aa7d8b60ed948f:0xcd2785a7b0604b2d!2m2!1d23.4575198!2d42.6321232!3e0?entry=ttu"
            target="_blank">
            <Box my="2" position="relative" overflow="hidden" borderRadius="md" shadow="lg" role="group">
                <StaticImage src="../../../images/four/location.png" alt={t('land.location.title')} />
                <Flex
                    position="absolute"
                    top="0"
                    left="0"
                    width="full"
                    height="full"
                    background="blackAlpha.700"
                    _groupHover={{ background: 'blackAlpha.600' }}
                    transition="0.5s"
                    justifyContent="center"
                    alignItems="center">
                    <Box
                        border="2px"
                        color="white"
                        bg="white"
                        p="2"
                        _groupHover={{ borderColor: 'gray.300' }}
                        transition="0.5s"
                        borderRadius="md"
                        width="20">
                        <StaticImage
                            src="../../../images/google_maps_logo.png"
                            alt="Google Maps"
                            layout="fullWidth"
                        />
                    </Box>
                </Flex>
            </Box>
        </Link>
    );
};

export default FourMap;
