import { FC } from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';
import { AiTwotoneTool } from 'react-icons/ai';
import { BsWater, BsFillCloudRainFill } from 'react-icons/bs';
import { FaRoad, FaFireExtinguisher } from 'react-icons/fa';
import { GiElectric } from 'react-icons/gi';
import { MdConnectedTv } from 'react-icons/md';

import LandFeature from './LandFeature/LandFeature';
import { LandFeaturesProps } from './LandFeatures.types';

const LandFeatures: FC<LandFeaturesProps> = ({ ...boxProps }) => (
    <Box {...boxProps}>
        <SimpleGrid columns={[1, 2, 3]} spacing={5}>
            <LandFeature icon={FaRoad} featureKey="land.features.features.road" />
            <LandFeature icon={GiElectric} featureKey="land.features.features.electricity" />
            <LandFeature icon={BsWater} featureKey="land.features.features.water" />
            <LandFeature icon={AiTwotoneTool} featureKey="land.features.features.sewage" />
            <LandFeature icon={BsFillCloudRainFill} featureKey="land.features.features.drain" />
            <LandFeature icon={FaFireExtinguisher} featureKey="land.features.features.fireSafety" />
            <LandFeature icon={MdConnectedTv} featureKey="land.features.features.internet" />
        </SimpleGrid>
    </Box>
);

export default LandFeatures;
