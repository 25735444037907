import { FC, useMemo } from 'react';

import { SimpleGrid, Box, Text } from '@chakra-ui/react';
import { useElementSize } from 'usehooks-ts';

import { Map, Trans } from '@/components/common';
import { FourMap } from '@/components/four';
import { ContextKeys } from '@/enums';

import { LandLocationProps } from './LandLocation.types';

const LandLocation: FC<LandLocationProps> = ({ contextKey = ContextKeys.TWO, ...boxProps }) => {
    const [leftRef, { height }] = useElementSize();

    const MapComponent = useMemo(() => {
        if (contextKey === ContextKeys.FOUR) {
            return <FourMap />;
        } else {
            <Map contextKey={contextKey} mapGlprops={{ height }} />;
        }
    }, [height, contextKey]);

    return (
        <SimpleGrid {...boxProps} columns={[1, 1, 2]} spacing={5}>
            <Box ref={leftRef}>
                <Text>
                    <Trans i18nKey={`${contextKey}.location.text`} />
                </Text>
            </Box>
            {MapComponent}
        </SimpleGrid>
    );
};

export default LandLocation;
