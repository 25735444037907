import { FC } from 'react';

import { Box, SimpleGrid, HStack, Button, Link, Heading, Text, Flex } from '@chakra-ui/react';
import * as Gatsby from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { Trans } from '@/components/common';

import { LandHeroProps } from './LandHero.types';

const LandHero: FC<LandHeroProps> = ({ ...boxProps }) => {
    const { t } = useTranslation();
    return (
        <Box position="relative" borderRadius="md" overflow="hidden" {...boxProps}>
            <StaticImage
                src="../../../images/land/hero-bg.jpg"
                alt="Парцели в Нови Хан"
                style={{
                    objectFit: 'contain',
                    position: 'absolute',
                    height: '100%',
                }}
            />
            <SimpleGrid columns={[1, 1, 2]}>
                <Box />
                <Box backgroundColor="blackAlpha.800" position="relative" zIndex={2}>
                    <Flex flexDir="column" justifyContent="space-between" p={[5, 10]}>
                        <Heading
                            fontWeight="hairline"
                            fontSize={['4xl', '3xl', '6xl']}
                            lineHeight="110%"
                            color="white">
                            <Trans i18nKey="land.hero.title" />
                        </Heading>
                        <Text color="white" fontWeight="thin">
                            <Trans i18nKey="land.hero.text" />
                        </Text>
                    </Flex>

                    <Box position="relative" bottom={0} w="100%">
                        <HStack spacing={0} wrap="wrap">
                            <Link
                                to="/land/scheme"
                                href="/land/scheme"
                                as={Gatsby.Link}
                                w={['100%', '100%', '50%']}>
                                <Button
                                    rounded="none"
                                    px={6}
                                    fontSize={['md', 'lg']}
                                    fontWeight={100}
                                    colorScheme="green"
                                    size="lg"
                                    w="full"
                                    m={0}>
                                    {t('land.availableProperties')}
                                </Button>
                            </Link>
                            <Link href="tel:00359888948945" w={['100%', '100%', '50%']}>
                                <Button
                                    rounded="none"
                                    bg="white"
                                    color="gray.800"
                                    fontSize={['lg', 'lg', 'sm', 'lg']}
                                    fontWeight={100}
                                    size="lg"
                                    w="full"
                                    m={0}>
                                    {t('common.contact.phone')}
                                </Button>
                            </Link>
                        </HStack>
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default LandHero;
