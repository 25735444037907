import { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { SchemeMap } from '@/components/common';
import { PARCEL_MAPPING } from '@/constants/land/mapping';
import { EStatusIcon } from '@/enums';
import useProperties, { Property } from '@/hooks/land/useProperties';
import useSchemeSize from '@/hooks/land/useSchemeSize';
import { hoveredPropetyAtom, selectedPropetyAtom } from '@/state/land/properties';

import { LandPropertiesSchemeProps } from './LandPropertiesScheme.types';

const LandPropertiesScheme: FC<LandPropertiesSchemeProps> = ({
    onModalOpen,
    highlightedProperty,
    ...boxProps
}) => {
    const { t } = useTranslation();
    const { imageWidth, imageHeight } = useSchemeSize();
    const [, setSelectedProperty] = useAtom(selectedPropetyAtom);
    const [hoveredProperty, setHoveredProperty] = useAtom(hoveredPropetyAtom);

    const [, properties] = useProperties();

    const mappedProperties = properties
        .filter(property => {
            if (highlightedProperty) {
                return (
                    Object.keys(PARCEL_MAPPING).includes(String(property.number)) &&
                    property.number === highlightedProperty.number
                );
            } else {
                return (
                    property.statusIcon === EStatusIcon.GREEN &&
                    Object.keys(PARCEL_MAPPING).includes(String(property.number))
                );
            }
        })
        .map(property => ({ coords: PARCEL_MAPPING[property.number], ...property }));

    const keyExtractor = (property: Property) => `parcel-2022-${property.number}`;

    const coordsExtractor = (property: Property) => (property as any).coords;

    const fillCondition = (property: Property) =>
        property.statusIcon === EStatusIcon.GREEN ? 'green.300' : 'transparent';

    const opacityCondition = (property: Property) =>
        hoveredProperty && hoveredProperty.number === property.number ? 0.8 : 0.3;

    const onClick = (property: Property) => {
        if (onModalOpen) {
            setSelectedProperty(property);
            onModalOpen();
        }
    };

    const onMouseEnter = (property: Property) => {
        setHoveredProperty(property);
    };

    const onMouseLeave = () => {
        setHoveredProperty(null);
    };

    return (
        <Box w="full" {...boxProps}>
            <Box position="relative">
                <StaticImage
                    src="../../../images/pup.png"
                    alt={t('land.scheme.title')}
                    layout="fullWidth"
                    style={{
                        mixBlendMode: 'multiply',
                        filter: 'contrast(1)',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
                <SchemeMap
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                    keyExtractor={keyExtractor}
                    coordsExtractor={coordsExtractor}
                    mappedItems={mappedProperties}
                    imageHeight={imageHeight}
                    imageWidth={imageWidth}
                    fillCondition={fillCondition}
                    opacityCondition={opacityCondition}
                />
                <Flex
                    top="0"
                    left="0"
                    position="absolute"
                    width="full"
                    height="full"
                    justifyContent="center"
                    alignItems="center">
                    <Box
                        border="2px"
                        borderRadius="md"
                        px="4"
                        py="2"
                        borderColor="red"
                        background="whiteAlpha.900">
                        <Text fontSize="2xl" fontWeight="bold" color="red">
                            {t('common.sold')}
                        </Text>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default LandPropertiesScheme;
