import { FC } from 'react';

import { Box, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Trans } from '@/components/common';

import { LandFeatureProps } from './LandFeature.types';

const LandFeature: FC<LandFeatureProps> = ({ icon, featureKey, ...boxProps }) => {
    const titleKey = `${featureKey}.title`;
    const textKey = `${featureKey}.text`;
    const { t } = useTranslation();
    return (
        <Box rounded="xl" w="100%" h="full" {...boxProps}>
            <Box
                bg="gray.100"
                _dark={{ bg: 'gray.900' }}
                py="5"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
                overflow="hidden">
                <Icon as={icon} color="green.500" opacity="0.3" position="absolute" left="2" fontSize="3xl" />
                <Text as="h3" fontSize="lg" color="gray.600" position="absolute">
                    {t(titleKey)}
                </Text>
            </Box>
            <Box px="4" py="2">
                <Text color="gray.600">
                    <Trans i18nKey={textKey} />
                </Text>
            </Box>
        </Box>
    );
};

export default LandFeature;
