import { FC } from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';
import { AiFillCar, AiTwotoneTool } from 'react-icons/ai';
import { BsWater, BsFillCloudRainFill } from 'react-icons/bs';
import { FaRoad } from 'react-icons/fa';
import { GiElectric, GiOilDrum } from 'react-icons/gi';
import { MdConnectedTv, MdFence } from 'react-icons/md';

import LandFeature from '@/components/land/LandFeatures/LandFeature/LandFeature';

import { FourFeaturesProps } from './FourFeatures.types';

const FourFeatures: FC<FourFeaturesProps> = ({ ...boxProps }) => (
    <Box {...boxProps}>
        <SimpleGrid columns={[1, 2, 2]} spacing={6}>
            <LandFeature icon={FaRoad} featureKey="four.features.features.road" />
            <LandFeature icon={GiElectric} featureKey="four.features.features.electricity" />
            <LandFeature icon={GiOilDrum} featureKey="four.features.features.gas" />
            <LandFeature icon={BsWater} featureKey="four.features.features.water" />
            <LandFeature icon={BsFillCloudRainFill} featureKey="four.features.features.drain" />
            <LandFeature icon={AiTwotoneTool} featureKey="four.features.features.sewage" />
            <LandFeature icon={MdConnectedTv} featureKey="four.features.features.internet" />
            <LandFeature icon={AiFillCar} featureKey="four.features.features.parking" />
            <LandFeature icon={MdFence} featureKey="four.features.features.fences" />
        </SimpleGrid>
    </Box>
);

export default FourFeatures;
