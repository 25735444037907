import { FC } from 'react';

import { Box, chakra, Wrap, WrapItem, Text, Button, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import useFiles from '@/hooks/land/useFiles';

import { LandFilesProps } from './LandFiles.types';

const LandFiles: FC<LandFilesProps> = ({ ...boxProps }) => {
    const { t } = useTranslation();
    const { roadFiles, kinderGardenFiles, waterFiles, electricityFiles } = useFiles();

    return (
        <Box {...boxProps}>
            <Box>
                <Text fontWeight={600} />
                <chakra.h3 fontSize={'2xl'} fontWeight="300" mb={5}>
                    {t('land.details.projects.road')}
                </chakra.h3>
                <Wrap>
                    {roadFiles.map((roadFile, index) => (
                        <WrapItem key={roadFile.name}>
                            <Link href={roadFile.publicURL} target={'_blank'}>
                                <Button>
                                    {t('common.file')} {index + 1}
                                </Button>
                            </Link>
                        </WrapItem>
                    ))}
                </Wrap>
            </Box>
            <Box>
                <chakra.h3 fontSize={'2xl'} fontWeight="300" my={5}>
                    {t('land.details.projects.electricity')}
                </chakra.h3>
                <Wrap>
                    {electricityFiles.map((roadFile, index) => (
                        <WrapItem key={roadFile.name}>
                            <Link href={roadFile.publicURL} target={'_blank'}>
                                <Button>
                                    {t('common.file')} {index + 1}
                                </Button>
                            </Link>
                        </WrapItem>
                    ))}
                </Wrap>
            </Box>
            <Box>
                <chakra.h3 fontSize={'2xl'} fontWeight="300" my={5}>
                    {t('land.details.projects.water')}
                </chakra.h3>
                <Wrap>
                    {waterFiles.map((roadFile, index) => (
                        <WrapItem key={roadFile.name}>
                            <Link href={roadFile.publicURL} target={'_blank'}>
                                <Button>
                                    {t('common.file')} {index + 1}
                                </Button>
                            </Link>
                        </WrapItem>
                    ))}
                </Wrap>
            </Box>
            <Box>
                <chakra.h3 fontSize={'2xl'} fontWeight="300" my={5}>
                    {t('land.details.projects.kindergarden')}
                </chakra.h3>
                <Wrap>
                    {kinderGardenFiles.map((kinderGardenFile, index) => (
                        <WrapItem key={kinderGardenFile.name}>
                            <Link href={kinderGardenFile.publicURL} target={'_blank'}>
                                <Button>
                                    {t('common.file')} {index + 1}
                                </Button>
                            </Link>
                        </WrapItem>
                    ))}
                </Wrap>
            </Box>
        </Box>
    );
};

export default LandFiles;
