import { FC } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';

import { LandNewsItemProps } from './LandNewsItem.types';

const LandNewsItem: FC<LandNewsItemProps> = ({ newsItem, ...boxProps }) => {
    const title = format(newsItem.date, 'dd/MM/yyyy');
    return (
        <Box pos="relative" h="full" borderRadius="md" bg="gray.100" overflow="hidden" {...boxProps}>
            <Box pos="absolute" w="2" h="full" alignSelf="flex-start" transitionDuration="0.25s" bg="orange.300" />
            <Box h="full" px={4} py={2} transitionDuration="0.25s" borderLeft="none">
                <Box>
                    <Text fontSize="l" fontWeight="light">
                        {title}
                    </Text>
                </Box>
                <Box alignSelf="flex-start" justifySelf="flex-end">
                    <Text fontWeight="hairline" noOfLines={3}>
                        <ReactMarkdown>{newsItem.content}</ReactMarkdown>
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};

export default LandNewsItem;
