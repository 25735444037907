import { FC } from 'react';

import { Flex, Link, Stack } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { FooterNavMapProps } from './Footer.types';
import FooterListHeader from './FooterListHeader';
import FooterListSubHeader from './FooterListSubHeader';

const FooterNavMap: FC<FooterNavMapProps> = ({ navItems, title }) => {
    const { t } = useTranslation();
    return (
        <Stack>
            <FooterListHeader>{t(title)}</FooterListHeader>
            {navItems.map(navItem => {
                if (navItem?.children && navItem.children.length > 0) {
                    return (
                        <Flex key={navItem.label} flexDirection="column">
                            <FooterListSubHeader>{t(navItem.label)}</FooterListSubHeader>
                            {navItem.children.map(navChild => (
                                <Link
                                    as={AnchorLink}
                                    to={navChild.href ?? '#'}
                                    key={navItem.label}
                                    my="0.5"
                                    fontSize="sm"
                                    fontWeight="light"
                                    width="fit-content">
                                    {t(navChild.label)}
                                </Link>
                            ))}
                        </Flex>
                    );
                } else {
                    return (
                        <Link
                            as={AnchorLink}
                            to={navItem.href ?? '#'}
                            key={navItem.label}
                            fontWeight="light"
                            fontSize="md"
                            width="fit-content">
                            {t(navItem.label)}
                        </Link>
                    );
                }
            })}
        </Stack>
    );
};

export default FooterNavMap;
