import { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { Header, Footer, GDPR } from '@/components/common';

import { HEADER_HEIGHT } from '../Header/Header';
import { LayoutProps } from './Layout.types';

const Layout: FC<LayoutProps> = ({ children, navItems, headerProps }) => (
    <>
        <Header navItems={navItems} {...headerProps} />
        <Box flex={1} bgGradient="linear(to-b, white, gray.100)" pos="relative" pt={HEADER_HEIGHT}>
            {children}
            <Footer bg="transparent" />
        </Box>
        <GDPR />
    </>
);
export default Layout;
