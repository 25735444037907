import { FC, useCallback, useMemo } from 'react';

import {
    Box,
    Stack,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    LinkProps,
    Text,
    TextProps,
    Divider,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { isLinkActive } from '@/utils/isLinkActive';

import { NavItem, NavProps } from '../Header.types';
import DesktopSubNav from './DesktopSubNav';

const LINK_COLOR_MAPPING = {
    0: {
        0: 'white',
        1: 'gray.900',
    },
    1: {
        0: 'gray.600',
        1: 'gray.900',
    },
};

const DIVIDER_COLOR_MAPPING = {
    0: {
        0: 'white',
        1: 'gray.300',
    },
    1: {
        0: 'gray.600',
        1: 'gray.500',
    },
};

const DesktopNav: FC<NavProps> = ({ navItems, isCollapsed = false, isInverseColors = false }) => {
    const { t } = useTranslation();
    const linkColor = useMemo(
        () =>
            LINK_COLOR_MAPPING[+isCollapsed as keyof typeof LINK_COLOR_MAPPING][
                +isInverseColors as keyof typeof LINK_COLOR_MAPPING
            ],
        [isCollapsed, isInverseColors],
    );
    const dividerColor = useMemo(
        () =>
            DIVIDER_COLOR_MAPPING[+isCollapsed as keyof typeof DIVIDER_COLOR_MAPPING][
                +isInverseColors as keyof typeof DIVIDER_COLOR_MAPPING
            ],
        [isCollapsed, isInverseColors],
    );

    const commonProps: Partial<LinkProps & TextProps> = useMemo(
        () => ({
            color: linkColor,
            _hover: {
                textDecoration: 'none',
                opacity: 1,
            },
            opacity: 0.8,
            px: 4,
            fontSize: 'md',
            textAlign: 'center',
        }),
        [linkColor],
    );
    const location = useLocation();

    const getLinkProps = useCallback(
        (navItem: NavItem) => {
            const isActive = isLinkActive(navItem, location.pathname);
            if (isActive) {
                return {
                    fontWeight: '600',
                    ...commonProps,
                };
            }
            return commonProps;
        },
        [location.pathname, commonProps],
    );

    return (
        <Stack direction="row" spacing="2">
            {navItems &&
                navItems.map((navItem, i) => (
                    <Box key={navItem.label} display="flex" justifyContent="center">
                        <Popover trigger={'hover'} placement={'bottom-start'}>
                            <PopoverTrigger>
                                {navItem.href ? (
                                    <Link
                                        as={AnchorLink}
                                        to={navItem.href ?? '#'}
                                        target={navItem.newTab ? '_blank' : '_self'}
                                        {...getLinkProps(navItem)}>
                                        {t(navItem.label)}
                                    </Link>
                                ) : (
                                    <Text cursor="pointer" {...getLinkProps(navItem)}>
                                        {t(navItem.label)}
                                    </Text>
                                )}
                            </PopoverTrigger>

                            {navItem.children && (
                                <PopoverContent
                                    border={0}
                                    boxShadow="lg"
                                    bg="whiteAlpha.700"
                                    backdropFilter="blur(5px)"
                                    p={4}
                                    roundedBottom="md"
                                    minW="sm">
                                    <Stack>
                                        {navItem.children.map(child => (
                                            <DesktopSubNav key={child.label} {...child} />
                                        ))}
                                    </Stack>
                                </PopoverContent>
                            )}
                        </Popover>
                        {i !== navItems.length - 1 && (
                            <Divider orientation="vertical" borderColor={dividerColor} />
                        )}
                    </Box>
                ))}
        </Stack>
    );
};

export default DesktopNav;
