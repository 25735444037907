import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { SchemeMap } from '@/components/common';
import { FILL_MAP } from '@/constants/four/scheme';
import { FourPropertyStatus } from '@/enums';
import useFourProperties, { FourProperty } from '@/hooks/four/useFourProperties';
import useSchemeSize from '@/hooks/four/useSchemeSize';
import { hoveredFourPropetyAtom, selectedFourPropetyAtom } from '@/state/four/fourProperties';

import { FourPropertiesSchemeProps } from './FourPropertiesScheme.types';

const FourPropertiesScheme: FC<FourPropertiesSchemeProps> = ({
    onModalOpen,
    highlightedProperty,
    ...boxProps
}) => {
    const { t } = useTranslation();
    const { imageWidth, imageHeight } = useSchemeSize();
    const [, setSelectedProperty] = useAtom(selectedFourPropetyAtom);
    const [hoveredProperty, setHoveredProperty] = useAtom(hoveredFourPropetyAtom);

    const [, properties] = useFourProperties();

    const mappedProperties = properties.filter(property => {
        if (highlightedProperty) {
            return !!property.coords && property.number === highlightedProperty.number;
        } else {
            return !!property.coords;
        }
    });

    const keyExtractor = (property: FourProperty) => `parcel-2022-${property.number}`;

    const coordsExtractor = (property: FourProperty) => property.coords;

    const fillCondition = (property: FourProperty) =>
        highlightedProperty ? 'green.300' : FILL_MAP[property.statusText as FourPropertyStatus];

    const opacityCondition = (property: FourProperty) =>
        highlightedProperty ? 1 : hoveredProperty && hoveredProperty.number === property.number ? 0.8 : 0.3;

    const onClick = (property: FourProperty) => {
        if (onModalOpen) {
            setSelectedProperty(property);
            onModalOpen();
        }
    };

    const onMouseEnter = (property: FourProperty) => {
        setHoveredProperty(property);
    };

    const onMouseLeave = () => {
        setHoveredProperty(null);
    };

    return (
        <Box width="full" overflow="hidden" {...boxProps}>
            <Box position="relative" width="full" mt={-1} ml={-1}>
                <StaticImage
                    src="../../../images/four/scheme.png"
                    alt={t('land.scheme.title')}
                    layout="fullWidth"
                    style={{
                        mixBlendMode: 'multiply',
                        filter: 'contrast(1)',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
                <SchemeMap
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                    keyExtractor={keyExtractor}
                    coordsExtractor={coordsExtractor}
                    mappedItems={mappedProperties}
                    imageHeight={imageHeight}
                    imageWidth={imageWidth}
                    fillCondition={fillCondition}
                    opacityCondition={opacityCondition}
                />
            </Box>
        </Box>
    );
};

export default FourPropertiesScheme;
