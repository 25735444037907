import { FC } from 'react';

import { Stack } from '@chakra-ui/react';

import { NavProps } from '../Header.types';
import MobileNavItem from './MobileNavItem';

const MobileNav: FC<NavProps> = ({ navItems, ...boxProps }) => (
    <Stack p={4} display={{ md: 'none' }} {...boxProps}>
        {navItems && navItems.map(navItem => <MobileNavItem key={navItem.label} {...navItem} />)}
    </Stack>
);

export default MobileNav;
