import { FC } from 'react';

import { Box, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import { ImageModal } from '@/components/common';

import { LandKinderGardenProps } from './LandKinderGarden.types';

const LandKinderGarden: FC<LandKinderGardenProps> = ({ ...boxProps }) => (
    <Box {...boxProps}>
        <SimpleGrid columns={[2, 4]} spacing={1}>
            <ImageModal title="Визуализация на детската градина">
                <StaticImage src="../../../images/kindergarden/1.png" alt="Визуализация на детската градина" />
            </ImageModal>
            <ImageModal title="Визуализация на детската градина">
                <StaticImage src="../../../images/kindergarden/2.png" alt="Визуализация на детската градина" />
            </ImageModal>
            <ImageModal title="Визуализация на детската градина">
                <StaticImage src="../../../images/kindergarden/3.png" alt="Визуализация на детската градина" />
            </ImageModal>
            <ImageModal title="Визуализация на детската градина">
                <StaticImage src="../../../images/kindergarden/4.png" alt="Визуализация на детската градина" />
            </ImageModal>
        </SimpleGrid>
    </Box>
);

export default LandKinderGarden;
