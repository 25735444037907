import { FC } from 'react';

import { Box, Container, ContainerProps } from '@chakra-ui/react';

const Main: FC<ContainerProps> = ({ children, ...containerProps }) => (
    <Container maxW="container.lg" pt={6} pb={16} {...containerProps}>
        <Box as="main">{children}</Box>
    </Container>
);

export default Main;
