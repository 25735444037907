import { graphql, useStaticQuery } from 'gatsby';

const useSchemeSize = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "pup.png" }) {
                childImageSharp {
                    original {
                        width
                        height
                    }
                }
            }
        }
    `);

    return {
        imageWidth: data.file.childImageSharp.original.width,
        imageHeight: data.file.childImageSharp.original.height,
    };
};

export default useSchemeSize;
