import { graphql, useStaticQuery } from 'gatsby';

interface SourcedFile {
    name: string;
    publicURL: string;
    sourceInstanceName: string;
}

const useFiles = () => {
    const files: SourcedFile[] = useStaticQuery(graphql`
        query {
            files: allFile(
                filter: { sourceInstanceName: { in: ["road", "electricity", "water", "kindergarden", "en"] } }
            ) {
                nodes {
                    publicURL
                    sourceInstanceName
                    name
                }
            }
        }
    `).files.nodes;
    const roadFiles = files.filter(file => file.sourceInstanceName === 'road');
    const electricityFiles = files.filter(file => file.sourceInstanceName === 'electricity');
    const waterFiles = files.filter(file => file.sourceInstanceName === 'water');
    const kinderGardenFiles = files.filter(file => file.sourceInstanceName === 'kindergarden');
    const englishFile = files.find(file => file.sourceInstanceName === 'en');
    return { roadFiles, electricityFiles, waterFiles, kinderGardenFiles, englishFile };
};

export default useFiles;
