import { FC } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { ImageModal } from '@/components/common';

import { GalleryImageProps } from './GalleryImage.types';

const GalleryImage: FC<GalleryImageProps> = ({ imageData, showTitle = true, ...boxProps }) => {
    const { t } = useTranslation();
    const imageDate = new Date(imageData.date);
    const imageTitle = format(imageDate, 'dd/MM/yyyy');
    const imageAlt = t('gallery.imageTitle', { imageDate, projectType: t(`${imageData.projectType}.name`) });

    let height: string | number = 'auto';

    const isVertical = imageData?.image?.height > imageData?.image?.width;
    height = isVertical ? '250px' : '100%';

    return (
        <Box alignSelf="flex-end" justifyContent="flex-end" alignItems="flex-end" cursor="pointer" {...boxProps}>
            <Box position="relative" overflow="hidden" borderRadius="md">
                <ImageModal title={imageAlt}>
                    <GatsbyImage style={{ height }} image={imageData.image} alt={imageAlt} />
                </ImageModal>
                {showTitle && (
                    <Box
                        overflow="hidden"
                        position="absolute"
                        bg="blackAlpha.700"
                        width="full"
                        bottom={0}
                        transition="ease-in-out"
                        transitionDuration="0.25s">
                        <Text color="white" p="1.5" noOfLines={1}>
                            {imageTitle}
                        </Text>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default GalleryImage;
