import { FC } from 'react';

import {
    Button,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Tr,
} from '@chakra-ui/react';
import * as Gatsby from 'gatsby';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { selectedFourPropetyAtom } from '@/state/four/fourProperties';
import { formatPrice } from '@/utils/formatPrice';
import { formatNumber } from '@/utils/four/formatNumber';
import { isPropertyAvailable } from '@/utils/four/isPropertyAvailable';

import { FourPropertyModalProps } from './FourPropertyModal.types';

const FourPropertyModal: FC<FourPropertyModalProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [selectedProperty, setSelectedProperty] = useAtom(selectedFourPropetyAtom);

    const handleClosePopup = () => {
        setSelectedProperty(null);
        onClose();
    };

    return (
        selectedProperty && (
            <Modal isOpen={isOpen} onClose={handleClosePopup} size="md">
                <ModalOverlay />
                <ModalContent alignSelf="center" justifyContent="center" position="absolute">
                    <ModalHeader>
                        {`
            ${t('common.number')} ${formatNumber(selectedProperty.number)}`}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Table>
                            <Tbody>
                                <Tr>
                                    <Td>{t('filters.size')}</Td>
                                    <Td>
                                        {selectedProperty.size} {t('common.sqm')}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>{t('four.scheme.streetArea')}</Td>
                                    <Td>
                                        {selectedProperty.streetArea} {t('common.m')}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>
                                        {t('filters.price')} {t('common.withVat')}
                                    </Td>
                                    <Td>{formatPrice(selectedProperty.price)}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                        {isPropertyAvailable(selectedProperty) && (
                            <Link as={Gatsby.Link} to={`/query/2024/${selectedProperty.number * 1000}`}>
                                <Button colorScheme="green" w="full" mt={4}>
                                    {t('common.query')}
                                </Button>
                            </Link>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    );
};

export default FourPropertyModal;
