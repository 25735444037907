import { FC, ReactNode } from 'react';

import {
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Modal,
    Flex,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { LanguageSwitch } from '@/components/v2';
import { modalAtom } from '@/state/modal/modal';

import Trans from '../Trans/Trans';

const ModalWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    const [modal, setModal] = useAtom(modalAtom);

    const handleClose = () => {
        setModal(false);
    };

    return (
        <>
            <Modal isOpen={modal} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('modal.title')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody whiteSpace="pre-wrap">
                        <Trans i18nKey="modal.body" />
                    </ModalBody>
                    <ModalBody whiteSpace="pre-wrap">
                        <Trans i18nKey="modal.footer" />
                    </ModalBody>
                    <ModalFooter width="full" justifyContent="center">
                        <Flex width="full" justifyContent="space-between" alignItems="center">
                            <LanguageSwitch />
                            <Button mr={3} onClick={handleClose}>
                                {t('common.close')}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {children}
        </>
    );
};

export default ModalWrapper;
