import { FC } from 'react';

import {
    Box,
    Button,
    Divider,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Flex,
    Text,
} from '@chakra-ui/react';
import { navigate } from 'gatsby';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { EStatusIcon } from '@/enums';
import { selectedPropetyAtom } from '@/state/land/properties';
import { formatPrice } from '@/utils/formatPrice';

import { LandPropertyDrawerProps } from './LandPropertyDrawer.types';

const LandPropertyDrawer: FC<LandPropertyDrawerProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const [selectedProperty] = useAtom(selectedPropetyAtom);

    const handleNavigate = (to: string) => () => {
        if (onClose) {
            onClose();
        }
        navigate(to);
    };

    if (!selectedProperty) {
        return null;
    }

    return (
        <DrawerContent
            bg="whiteAlpha.800"
            backdropFilter="blur(6px)"
            ml={[0, 4]}
            my={[0, 4]}
            borderRadius={['none', 'lg']}>
            <DrawerCloseButton />
            <DrawerHeader>{t('common.propertyNumber', { number: selectedProperty.number })}</DrawerHeader>
            <DrawerBody fontSize="lg">
                <Flex justifyContent="space-between">
                    <Text>{t('land.scheme.headers.status')}</Text>
                    <Text
                        fontWeight="semibold"
                        color={selectedProperty.statusIcon === EStatusIcon.GREEN ? 'green.500' : 'black'}>
                        {selectedProperty.statusText}
                    </Text>
                </Flex>
                <Divider my="2" borderColor="blackAlpha.300" />
                <Flex justifyContent="space-between">
                    <Text>{t('filters.size')}</Text>
                    <Text fontWeight="semibold">
                        {selectedProperty.size} {t('common.sqm')}
                    </Text>
                </Flex>
                <Divider my="2" borderColor="blackAlpha.300" />
                <Flex justifyContent="space-between">
                    <Text>
                        {t('filters.price')} {t('common.withVat')}
                    </Text>
                    <Text fontWeight="semibold">{selectedProperty && formatPrice(selectedProperty.price)}</Text>
                </Flex>
                <Box mt="6">
                    {selectedProperty.statusIcon && selectedProperty.statusIcon === EStatusIcon.GREEN && (
                        <Box>
                            <Button
                                colorScheme="green"
                                w="full"
                                onClick={handleNavigate(`/query/${selectedProperty.number}`)}>
                                {t('common.query')}
                            </Button>
                            <Flex flexDirection="column" mt="2">
                                <Text textAlign="center" fontWeight="light">
                                    {t('common.orCall')}
                                </Text>
                                <Text textAlign="center">{t('common.contact.phone')}</Text>
                            </Flex>
                        </Box>
                    )}
                </Box>
                <Box mt="4">
                    <Button
                        onClick={handleNavigate('/land/scheme')}
                        colorScheme="blackAlpha"
                        w="full"
                        variant="outline">
                        {t('land.availableProperties')}
                    </Button>
                </Box>
            </DrawerBody>
        </DrawerContent>
    );
};

export default LandPropertyDrawer;
