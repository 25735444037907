import { graphql, useStaticQuery } from 'gatsby';

import { mapFrontmatter } from '@/utils/mapFrontmatter';

type NewsNode = {
    id: string;
    date: string;
    content: string;
};

export type NewsItem = NewsNode & {
    date: Date;
};

const useNews = () => {
    const {
        news: { nodes: newsNodes },
    } = useStaticQuery(graphql`
        query {
            news: allFile(filter: { sourceInstanceName: { eq: "news" } }, sort: { changeTime: DESC }) {
                nodes {
                    childMarkdownRemark {
                        frontmatter {
                            date
                            content
                        }
                    }
                    id
                }
            }
        }
    `);
    return mapFrontmatter<NewsNode[]>(newsNodes).map(newsNode => ({
        ...newsNode,
        date: new Date(newsNode.date),
    })) as NewsItem[];
};

export default useNews;
