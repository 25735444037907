import { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { Drawer, DrawerOverlay, useDisclosure } from '@chakra-ui/react';

import { DRAWER_MAPPING } from '@/constants/common';
import { DrawerTypes } from '@/enums';

const DrawerContext = createContext({
    openDrawer: (_drawerType: DrawerTypes, _drawerConfig: any) => {},
    onClose: () => {},
});

export const useDrawer = () => useContext(DrawerContext);

const DrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [drawerType, setDrawerType] = useState<DrawerTypes>(DrawerTypes.FOUR_PROPERTY);
    const [drawerConfig, setDrawerConfig] = useState({});
    const DrawerComponent = useMemo(() => DRAWER_MAPPING[drawerType], [drawerType]);

    const openDrawer = (_drawerType: DrawerTypes, _drawerConfig: any) => {
        onOpen();
        setDrawerType(_drawerType);
        setDrawerConfig(_drawerConfig);
    };

    const closeDrawer = () => {
        onClose();
    };

    return (
        <DrawerContext.Provider value={{ openDrawer, onClose }}>
            {children}
            <Drawer onClose={onClose} isOpen={isOpen} placement="left">
                <DrawerOverlay background="blackAlpha.700" />
                <DrawerComponent {...(drawerConfig as any)} onClose={closeDrawer} />
            </Drawer>
        </DrawerContext.Provider>
    );
};

export default DrawerProvider;
