import { FC, useMemo } from 'react';

import { Box, Stack, Link, Popover, PopoverTrigger, PopoverContent, useColorModeValue } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { NavProps } from '../Header.types';
import DesktopSubNav from './DesktopSubNav';

const DesktopNav: FC<NavProps> = ({ navItems }) => {
    const { t } = useTranslation();
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('orange.500', 'white');
    const linkProps = useMemo(
        () => ({
            color: linkColor,
            _hover: {
                textDecoration: 'none',
                color: linkHoverColor,
            },
            px: 4,
            fontSize: 'md',
        }),
        [linkColor, linkHoverColor],
    );
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4}>
            {navItems &&
                navItems.map(navItem => (
                    <Box key={navItem.label}>
                        <Popover trigger={'hover'} placement={'bottom-start'}>
                            <PopoverTrigger>
                                {navItem.href ? (
                                    <Link
                                        as={AnchorLink}
                                        to={navItem.href ?? '#'}
                                        fontWeight={400}
                                        target={navItem.newTab ? '_blank' : '_self'}
                                        {...linkProps}>
                                        {t(navItem.label)}
                                    </Link>
                                ) : (
                                    <Link fontWeight={500} {...linkProps}>
                                        {t(navItem.label)}
                                    </Link>
                                )}
                            </PopoverTrigger>

                            {navItem.children && (
                                <PopoverContent
                                    border={0}
                                    boxShadow="lg"
                                    bg={popoverContentBgColor}
                                    p={4}
                                    roundedBottom="md"
                                    minW="sm">
                                    <Stack>
                                        {navItem.children.map(child => (
                                            <DesktopSubNav key={child.label} {...child} />
                                        ))}
                                    </Stack>
                                </PopoverContent>
                            )}
                        </Popover>
                    </Box>
                ))}
        </Stack>
    );
};

export default DesktopNav;
