import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';

const useAssets = () => {
    const {
        assets: { nodes: assetNodes },
    } = useStaticQuery(graphql`
        {
            assets: allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 520)
                    }
                    relativePath
                }
            }
        }
    `);

    const assets = assetNodes.map((assetNode: any) => {
        const imageData = getImage(assetNode);
        const relativePath = `/assets/${assetNode.relativePath}`;
        return { imageData, relativePath };
    }) as { imageData: IGatsbyImageData; relativePath: string }[];
    return assets;
};

export default useAssets;
