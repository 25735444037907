import { format } from 'date-fns';
import { groupBy } from 'lodash-es';

import { GalleryPeriods } from '@/enums';

const formatMapping = (period: GalleryPeriods): string => {
    if (period === GalleryPeriods.DAY) {
        return 'dd/MM/yyyy';
    } else if (period === GalleryPeriods.MONTH) {
        return 'MM/yyyy';
    } else {
        return 'yyyy';
    }
};

export const groupByPeriod = <T>(items: T[], key: keyof T, period: GalleryPeriods) =>
    groupBy(items, item => {
        const value = item[key] as Date;
        const formatString = formatMapping(period);
        return format(value, formatString);
    });
