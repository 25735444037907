import { FC } from 'react';

import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
} from '@chakra-ui/react';

import { ImageModalProps } from './ImageModal.types';

const ImageModal: FC<ImageModalProps> = ({ title, children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box onClick={onOpen}>{children}</Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Box onClick={onOpen} cursor={'pointer'}>
                {children}
            </Box>
        </>
    );
};

export default ImageModal;
